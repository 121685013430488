import { fromJS } from 'immutable';

const initialState = fromJS({
  masses: []
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'CLEAR_MASSES': {
      return state.set('masses', fromJS([]));
    }

    case 'ADD_MASSES': {
      const sortedMasses = fromJS(action.masses).sort((a, b) => {
        return new Date(a.get('date')) - new Date(b.get('date'));
      });
      return state.set('masses', sortedMasses);
    }

    case 'REMOVE_MASS': {
      const removableMass = state.get('masses').findIndex(mass => mass.get('id') === fromJS(action.mass));
      if (removableMass !== -1) {
        return state.set('masses', state.get('masses').delete(removableMass));
      }
      return state;
    }
  
    case 'ADD_MASS': {
     const newMass = fromJS(action.mass);
      if (state.get('masses').find(mass => mass.get('id') === newMass.get('id')) === undefined) {
        return state.set('masses', state.get('masses').push(newMass));
      }
      return state;
    }

    case 'CHANGE_MASS': {
      const changingIndex = state.get('masses').findIndex( mass => mass.get('id') === fromJS(action.mass.id));
      
      if (changingIndex === -1) return state;

      if (state.get('masses').get(changingIndex) === fromJS(action.mass)) {
        return state;
      }

      const newMasses = state.get('masses').set(changingIndex, fromJS(action.mass));
      return state.set('masses', newMasses);
    }

    case 'UPDATE_LAST_PATH': {
      const changingIndex = state.get('masses').findIndex( mass => mass.get('id') === action.massId);
      let changingMass = state.get('masses').get(changingIndex);
      let paths = changingMass.get('paths');
      paths = paths.set(paths.size - 1, fromJS(action.path));
      const newMasses = state.get('masses').set(changingIndex, changingMass.set('paths', paths));
      return state.set('masses', newMasses);
    }

    case 'ADD_PATH_TO_LAST_MASS': {
      const changingMass = state.get('masses').last();
      let paths = changingMass.get('paths');
      paths = paths.push(fromJS(action.path));
      const newMasses = state.get('masses').set(state.get('masses').size - 1, changingMass.set('paths', paths));
      return state.set('masses', newMasses);
    }

    case 'REMOVE_LAST_PATH': {
      const changingIndex = state.get('masses').findIndex( mass => mass.get('id') === action.massId);
      let changingMass = state.get('masses').get(changingIndex);
      let paths = changingMass.get('paths');
      paths = paths.pop();
      const newMasses = state.get('masses').set(changingIndex, changingMass.set('paths', paths));
      return state.set('masses', newMasses);
    }

    default: {
      return state;
    }
  }
};