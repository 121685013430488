import React from 'react';
import { connect } from 'react-redux';
import { addMass, addMasses, changeMass, removeMassById, clearMasses, updateLastPath,
         addPathToLastMass, removeLastPath } from './Actions';
import { selectLoad } from '../loads/Actions';
import { DataField, showNotice, showConfirm, showMessage, ContractSelect,
         ConstructionSiteSelect, TruckSelect, selectTruck, Weather, showAlertConfirm } from 'components';
import { fetch, integerValue, floatValue, Socket, timer, toETRSTM35FIN, testIsValidRoadInfo,
         mode, toRadians, toDegrees, toWGS84, stateValueParser, fetchSensorData,
         calculateRoadDistance, getRoadData, getRoadCoordinates } from '../utils.js';
import { RoadwaySelect, LaneSelect, DirectionSelect, WidthSelect, ThicknessSelect,
         AccuracyFixer, MassPerSquareSelect, MapView, GrooveLocation } from './Components';
import ChangeMassView from './Components';
import Loads from '../loads/Loads';
import { XYPlot, YAxis, LineSeries, Crosshair, DiscreteColorLegend } from 'react-vis';
import '../../../node_modules/react-vis/dist/style.css';
import './Mass.css';

const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;
let grooveLocation = null;

let locationValues = {};
locationValues['L1'] = 'Keskisauma';
locationValues['L2'] = 'Vasen ura';
locationValues['L3'] = 'Ajourien väli';
locationValues['L4'] = 'Oikea ura';
locationValues['L5'] = 'Reuna + Piennar';

const View = props => {
  if (props.view === 0) {
    return (
      <div>
        <BasicInfo changeState={props.changeState} width={props.width}
                   thickness={props.thickness} massPerSquare={props.massPerSquare}
                   lastWidth={props.lastWidth} lastMassPerSquare={props.lastMassPerSquare}
                   lastThickness={props.lastThickness} REM={props.REM}
                   groove={props.groove} sensor={props.sensor}
                   widthSensor={props.widthSensor}
                   toggleSensors={props.toggleSensors}
                   targetWidth={props.targetWidth}
                   setTargetWidth={props.setTargetWidth}
                   acceptTargetWidth={props.acceptTargetWidth}
                   sensorDevices={props.sensorDevices}
                   selectedSensorDevice={props.selectedSensorDevice}
                   store={props.store} />
      </div>
    );
  }
  else if (props.view === 1) {
    return (
      <div>
        <MassInput changeState={props.changeState} startRoadPart={props.startRoadPart}
                   startRoadPole={props.startRoadPole}
                   road={props.selectedConstructionSite ? props.selectedConstructionSite.get('road_number') : '-'} 
                   direction={props.direction}
                   roadway={props.roadway}
                   lane={props.lane}
                   width={props.width}
                   newMass={props.newMass}
                   useLocation={props.useLocation}
                   mass={props.mass}
                   smallAreas={props.smallAreas}
                   levelingMass={props.levelingMass} attentions={props.attentions}
                   makeEndForPath={props.makeEndForPath}
                   newPath={props.newPath}
                   massPerSquare={props.massPerSquare}
                   dailyTruckMass={props.dailyTruckMass}
                   dailySmallAreas={props.dailySmallAreas} dailyLevelingMass={props.dailyLevelingMass}
                   dailyCount={props.dailyCount} lastDirection={props.lastDirection}
                   lastRoadway={props.lastRoadway} lastLane={props.lastLane}
                   lastPole={props.lastPole} lastPart={props.lastPart}
                   truckRegisterNumber={props.truckRegisterNumber}
                   selectedLoad={props.selectedLoad} resetTruck={props.resetTruck}
                   autoGPS={props.autoGPS}
                   disableSubmit={props.disableSubmit}
                   disableNewPath={props.disableNewPath}
                   lastTemperature={props.lastTemperature}
                   temperature={props.temperature}
                   groove={props.groove}
                   lastLocationOnRoad={props.lastLocationOnRoad}
                   disableEndForPath={props.disableEndForPath}
                   store={props.store} />
      </div>
    );
  }
  else if (props.view === 2) {
    return (
      <div>
        <TrackingInfo changeState={props.changeState} masses={props.masses}
                      target={props.selectedConstructionSite} dailyMass={props.dailyMass}
                      loading={props.loadingMasses} removeMass={props.confirmRemoveMass}
                      removeLastPath={props.removeLastPath}
                      removeLocalMass={props.confirmRemoveLocalMass}
                      roadNumber={props.location_road_number} roadPart={props.location_road_part}
                      roadPole={props.location_road_pole} accuracy={props.accuracy}
                      time={props.locationTime} changeMass={props.changeMass}
                      dailyCurrentMassPerSquare={props.dailyCurrentMassPerSquare}
                      wholeMass={props.wholeMass} wholeCurrentMassPerSquare={props.wholeCurrentMassPerSquare}
                      toggleAddSmallArea={props.toggleAddSmallArea} resetCrosshairValue={props.resetCrosshairValue}
                      crosshairValue={props.crosshairValue} setCrosshairValue={props.setCrosshairValue}
                      graphData={props.graphData} graphData2={props.graphData2}
                      graphWholeData={props.graphWholeData}
                      latitude={props.latitude} longitude={props.longitude} />
      </div>
    );
  }
  else if (props.view === 3) {
    return (
      <div>
        <MapView masses={props.masses} yourLaditude={props.latitude} yourLongitude={props.longitude}
                 roadNumber={props.roadNumber} roadPart={props.roadPart}
                 roadPole={props.roadPole} accuracy={props.accuracy}
                 showMessage={props.showMessage} loads={props.loads} site={props.selectedConstructionSite}
                 paths={props.paths} grooveValues={locationValues}
                 organizationId={props.organizationId} mapPaths={props.mapPaths}
                 mapZoom={props.mapZoom} mapPosition={props.mapPosition} />
      </div>
    );
  }
  else if (props.view === 4) {
    return (
      <div>
        <REMInfo changeState={props.changeState}
                 millingDeepnessRight={props.millingDeepnessRight}
                 millingDeepnessLeft={props.millingDeepnessLeft}
                 REMTemperature={props.REMTemperature}
                 lastMillingDeepnessRight={props.lastMillingDeepnessRight}
                 lastMillingDeepnessLeft={props.lastMillingDeepnessLeft}
                 lastREMTemperaturee={props.lastREMTemperature}
                 deepnessSensor={props.deepnessSensor}
                 temperatureSensor={props.temperatureSensor} 
                 temperatureREMValue={props.temperatureREMValue}
                 deepnessSensorLeftValue={props.deepnessSensorLeftValue}
                 deepnessSensorRightValue={props.deepnessSensorRightValue} />
      </div>
    );
  }
  else {
    return (
      <div>
        <SensorInfo changeState={props.changeState}
                    widthSensor={props.widthSensor}
                    widthSensorLeftValue={props.widthSensorLeftValue}
                    widthSensorRightValue={props.widthSensorRightValue}
                    widthSensorValue={props.widthSensorValue}
                    toggleWidthSensor={props.toggleWidthSensor}
                    lengthBetweenWidthSensors={props.lengthBetweenWidthSensors}

                    toggleDeepnessSensor={props.toggleDeepnessSensor}
                    deepnessSensor={props.deepnessSensor}
                    deepnessAfterSensorLeftValue={props.deepnessAfterSensorLeftValue}
                    deepnessAfterSensorRightValue={props.deepnessAfterSensorRightValue}
                    deepnessOriginalSensorLeftValue={props.deepnessOriginalSensorLeftValue}
                    deepnessOriginalSensorRightValue={props.deepnessOriginalSensorRightValue}
                    deepnessZeroLeftOriginal={props.deepnessZeroLeftOriginal}
                    deepnessZeroLeftAfter={props.deepnessZeroLeftAfter}
                    deepnessZeroRightOriginal={props.deepnessZeroRightOriginal}
                    deepnessZeroRightAfter={props.deepnessZeroRightAfter}
                    deepnessSensorLeftValue={props.deepnessSensorLeftValue}
                    deepnessSensorRightValue={props.deepnessSensorRightValue}
                    deepnessCalibrationDate={props.deepnessCalibrationDate}
                    deepnessCalibrationTime={props.deepnessCalibrationTime}
                    toggleDeepnessCalibrationInfo={props.toggleDeepnessCalibrationInfo}
                    reverseLeftDeepness={props.reverseLeftDeepness}
                    reverseRightDeepness={props.reverseRightDeepness}
                    deepnessLeftOriginalCalibration={props.deepnessLeftOriginalCalibration}
                    deepnessLeftAfterCalibration={props.deepnessLeftAfterCalibration}
                    deepnessRightOriginalCalibration={props.deepnessRightOriginalCalibration}
                    deepnessRightAfterCalibration={props.deepnessRightAfterCalibration}

                    toggleTemperatureSensor={props.toggleTemperatureSensor}
                    temperatureSensor={props.temperatureSensor}
                    temperatureLeftValue={props.temperatureLeftValue}
                    temperatureMiddleValue={props.temperatureMiddleValue}
                    temperatureRightValue={props.temperatureRightValue}
                    temperatureREMValue={props.temperatureREMValue}

                    lastSensortime={props.lastSensortime}
                    lastSelectedSensorDevice={props.lastSelectedSensorDevice}
                    lastSensorLatitude={props.lastSensorLatitude}
                    lastSensorLongitude={props.lastSensorLongitude} />
      </div>
    );
  }
};

const BasicInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Perustiedot</h4>
      </legend>
      <div className='row'>
        <div className='column'>
          <ContractSelect required store={props.store} />
        </div>
        <div className='column'>
          <ConstructionSiteSelect required store={props.store} />
        </div>
      </div>
      <div className='row'>
        <div className='column'>
          <WidthSelect id='width' onChange={props.changeState}
                       value={props.width} last={props.lastWidth} />
        </div>
        { props.widthSensor ?
          <div className='column'>
            <label htmlFor='targetWidth'>Tavoite leveys (m)</label>
            {props.setTargetWidth ? <div>{'Asetettu: ' + props.setTargetWidth + ' m'}<br/></div> : null}
            <input id='targetWidth' type='tel'
                   onChange={props.changeState.bind(this, 'targetWidth', 'float', 0.0)} 
                   value={props.targetWidth} required/>
            <button onClick={props.acceptTargetWidth}>
              Aseta tavoite leveys
            </button>
          </div>
          : null
        }
      </div>
      <div className='row'>
        <div className='column'>
          <MassPerSquareSelect id='mass-per-square' onChange={props.changeState}
                               value={props.massPerSquare} last={props.lastMassPerSquare} />
        </div>
        <div className='column'>
          <ThicknessSelect id='thickness' onChange={props.changeState}
                           value={props.thickness} last={props.lastThickness} />
        </div>
        <div className='column'>
          <label className='checkbox'>
            REM
            <input type='checkbox'
                    onChange={props.changeState.bind(this, 'REM', 'boolean', true)} 
                    checked={props.REM} />
          </label>
          <label className='checkbox'>
            Ura
            <input type='checkbox'
                    onChange={props.changeState.bind(this, 'groove', 'boolean', true)} 
                    checked={props.groove} />
          </label>
        </div>
        <div className='column'>
          <label className='checkbox'>
            Käytä Sensoreita
            <input type='checkbox'
                    onChange={props.toggleSensors} 
                    checked={props.sensor} />
          </label>
          { props.sensor ?
            <label>
              Sensorilaite
              <select onChange={props.changeState.bind(this, 'selectedSensorDevice', 'string', true)} 
                      value={props.selectedSensorDevice} >
              <option value={''}>Valitse laite</option>
                { props.sensorDevices.map(device => (
                    <option key={device} value={device}>
                      {device}
                    </option>
                  ))
                }
              </select>
            </label>
            : null
          }
        </div>
      </div>
    </fieldset>
  );
};

const REMInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>REM-tiedot</h4>
      </legend>
        <div className='row'>
          <div className='column'>
            <label htmlFor='millingDepthRight'>Jyrsintäsyvyys (mm) oikea</label>
            Edellinen: {props.lastMillingDeepnessRight || '-'} mm
            <input id='millingDepthRight' type='tel'
                   value={props.deepnessSensor ? props.deepnessSensorLeftValue : props.millingDeepnessRight || ''}
                   onChange={props.changeState.bind(this, 'millingDeepnessRight', 'float', 0.0)}
                   readOnly={props.deepnessSensor} />
          </div>
          <div className='column'>
            <label htmlFor='millingDepthLeft'>Jyrsintäsyvyys (mm) vasen</label>
            Edellinen: {props.lastMillingDeepnessLeft || '-'} mm
            <input id='millingDepthLeft' type='tel'
                   value={props.deepnessSensor ? props.deepnessSensorRightValue : props.millingDeepnessLeft || ''}
                   onChange={props.changeState.bind(this, 'millingDeepnessLeft', 'float', 0.0)}
                   readOnly={props.deepnessSensor} />
          </div>
          <div className='column'>
            <label htmlFor='REMTemperature'>Kuumennetun alustan lämpötila (°C)</label>
            Edellinen: {props.lastREMTemperature || '-'} °C
            <input id='REMTemperature' type='tel'
                  value={props.temperatureSensor ? props.temperatureREMValue :
                         props.REMTemperature || ''}
                  onChange={props.changeState.bind(this, 'REMTemperature', 'float', 0.0)}
                  readOnly={props.temperatureSensor} />
          </div>
        </div>
    </fieldset>
  );
}

const SensorInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Sensoritiedot</h4>
      </legend>
      <p>
        {
          props.lastSensortime ?
          <p>
            <b>Laite:</b> {props.lastSelectedSensorDevice} 
            <b> Viimeisin arvo saatu:</b> {props.lastSensortime.getDate() + '.' + (props.lastSensortime.getMonth() + 1) +
              '.' + props.lastSensortime.getFullYear() + ' ' + paddedNumber(props.lastSensortime.getHours()) +
              ':' + paddedNumber(props.lastSensortime.getMinutes())} 
            <b> Sijainti:</b> {Math.round(props.lastSensorLatitude * 10000) / 10000 + ' ' + Math.round(props.lastSensorLongitude * 10000) / 10000}
          </p>
          : null
        }
      </p>
        <div className='row borders'>
          <div className='column'>
            <label className='checkbox'>
              Leveys sensorit
              <input type='checkbox'
                    onChange={props.toggleWidthSensor} 
                    checked={props.widthSensor} />
            </label>
          </div>
          { props.widthSensor ? 
            <div className='row'>
              <div className='column'>
                <label htmlFor='lengthBetweenWidthSensors'>Sensoreiden väli (cm)</label>
                <input id='lengthBetweenWidthSensors' type='tel'
                       className={props.lengthBetweenWidthSensors ? '' : 'required'}
                       value={props.lengthBetweenWidthSensors || ''}
                       onChange={props.changeState.bind(this, 'lengthBetweenWidthSensors', 'float', 0.0)} />
              </div>
              <div className='column'>
                <label htmlFor='widthSensorLeftValue'>Vasen arvo (cm)</label>
                <input id='widthSensorLeftValue' type='tel'
                       value={props.widthSensorLeftValue || '-'}
                       readOnly/>
              </div>
              <div className='column'>
                <label htmlFor='widthSensorRightValue'>Oikea arvo (cm)</label>
                <input id='widthSensorRightValue' type='tel'
                       value={props.widthSensorRightValue || '-'}
                       readOnly/>
              </div>
              <div className='column'>
                <label htmlFor='widthSensorValue'>Leveys (m)</label>
                <input id='widthSensorValue' type='tel'
                       value={props.widthSensorValue || '-'}
                       readOnly/>
              </div>
            </div>
            :
            null
          }
        </div>

        <div className='row borders'>
          <div className='column'>
            <label className='checkbox'>
              Syvyys sensorit
              <input type='checkbox'
                    onChange={props.toggleDeepnessSensor} 
                    checked={props.deepnessSensor} />
            </label>
          </div>
          { props.deepnessSensor ?
            <div>
              <div className='row'>
                <div className='column'>
                  <label htmlFor='deepnessCalibrationDate'>
                    0 tason aika:
                    <div className='info'
                        onClick={props.toggleDeepnessCalibrationInfo}>
                      ?
                    </div>
                  </label>
                  <input type='date' id='deepnessCalibrationDate'
                        className={props.deepnessCalibrationDate ? '' : 'required'}
                        value={props.deepnessCalibrationDate || ''}
                        onChange={props.changeState.bind(this, 'deepnessCalibrationDate', 'time', null)} />
                  <input type='time' id='deepnessCalibrationTime'
                        className={props.deepnessCalibrationTime ? '' : 'required'}
                        value={props.deepnessCalibrationTime || ''}
                        onChange={props.changeState.bind(this, 'deepnessCalibrationTime', 'time', null)} />
                </div>
              </div>
              <div className='row'>
                <div className='column'>
                  <label htmlFor='deepnessLeftOriginalCalibration'>Vasen alkuperäinen 0 tasossa syvyys (mm)</label>
                  <input id='deepnessLeftOriginalCalibration' type='tel'
                        value={props.deepnessLeftOriginalCalibration}
                        onChange={props.changeState.bind(this, 'deepnessLeftOriginalCalibration', 'float', 0.0)} />
                </div>
                <div className='column'>
                  <label htmlFor='deepnessLeftAfterCalibration'>Vasen jälkinmäinen 0 tasossa syvyys (mm)</label>
                  <input id='deepnessLeftAfterCalibration' type='tel'
                        value={props.deepnessLeftAfterCalibration}
                        onChange={props.changeState.bind(this, 'deepnessLeftAfterCalibration', 'float', 0.0)} />
                </div>
              </div>
              <div className='row'>
                <div className='column'>
                  <label htmlFor='deepnessRightOriginalCalibration'>Oikea alkuperäinen 0 tasossa syvyys (mm)</label>
                  <input id='deepnessRightOriginalCalibration' type='tel'
                        value={props.deepnessRightOriginalCalibration}
                        onChange={props.changeState.bind(this, 'deepnessRightOriginalCalibration', 'float', 0.0)} />
                </div>
                <div className='column'>
                  <label htmlFor='deepnessRightAfterCalibration'>Oikea jälkinmäinen 0 tasossa syvyys (mm)</label>
                  <input id='deepnessRightAfterCalibration' type='tel'
                        value={props.deepnessRightAfterCalibration}
                        onChange={props.changeState.bind(this, 'deepnessRightAfterCalibration', 'float', 0.0)} />
                </div>
              </div>
              <div className='row'>
                <div className='column'>
                  Käännä vasemmat syvyys sensorit:
                  <br/>
                  <label>
                    Ei
                    <input type='radio' name='reverseLeftDeepness' value={0}
                            onChange={props.changeState.bind(this, 'reverseLeftDeepness', 'integer', 0)}
                            checked={props.reverseLeftDeepness === 0} />
                  </label>
                  <label>
                    Kyllä
                    <input type='radio' name='reverseLeftDeepness' value={1}
                            onChange={props.changeState.bind(this, 'reverseLeftDeepness', 'integer', 1)}
                            checked={props.reverseLeftDeepness === 1} />
                  </label>
                </div>
                <div className='column'>
                  Käännä oikea syvyys sensorit:
                  <br/>
                  <label>
                    Ei
                    <input type='radio' name='reverseRightDeepness' value={0}
                            onChange={props.changeState.bind(this, 'reverseRightDeepness', 'integer', 0)}
                            checked={props.reverseRightDeepness === 0} />
                  </label>
                  <label>
                    Kyllä
                    <input type='radio' name='reverseRightDeepness' value={1}
                            onChange={props.changeState.bind(this, 'reverseRightDeepness', 'integer', 1)}
                            checked={props.reverseRightDeepness === 1} />
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='column'>
                  <label htmlFor='deepnessOriginalSensorLeftValue'>Alkuperäinen vasen arvo (mm)</label>
                  <input id='deepnessOriginalSensorLeftValue' type='tel'
                        value={props.deepnessOriginalSensorLeftValue || '-'}
                        readOnly />
                  <span>
                    Nolla taso: {props.deepnessZeroLeftOriginal || '-'}
                  </span>
                </div>
                <div className='column'>
                  <label htmlFor='deepnessOriginalSensorRightValue'>Alkuperäinen oikea arvo (mm)</label>
                  <input id='deepnessOriginalSensorRightValue' type='tel'
                        value={props.deepnessOriginalSensorRightValue || '-'}
                        readOnly />
                  <span>
                    Nolla taso: {props.deepnessZeroRightOriginal || '-'}
                  </span>
                </div>
                <div className='column'>
                  <label htmlFor='deepnessAfterSensorLeftValue'>Jälki vasen arvo (mm)</label>
                  <input id='deepnessAfterSensorLeftValue' type='tel'
                        value={props.deepnessAfterSensorLeftValue || '-'}
                        readOnly />
                  <span>
                    Nolla taso: {props.deepnessZeroLeftAfter || '-'}
                  </span>
                </div>
                <div className='column'>
                  <label htmlFor='deepnessAfterSensorRightValue'>Jälki oikea arvo (mm)</label>
                  <input id='deepnessAfterSensorRightValue' type='tel'
                        value={props.deepnessAfterSensorRightValue || '-'}
                        readOnly />
                  <span>
                    Nolla taso: {props.deepnessZeroRightAfter || '-'}
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='column'>
                  <label htmlFor='deepnessSensorLeftValue'>Syvyys vasen (mm)</label>
                  <input id='deepnessSensorLeftValue' type='tel'
                        value={props.deepnessSensorLeftValue != null && !isNaN(props.deepnessSensorLeftValue)
                          ? props.deepnessSensorLeftValue : '-'}
                        readOnly />
                </div>
                <div className='column'>
                    <label htmlFor='deepnessSensorRightValue'>Syvyys oikea (mm)</label>
                    <input id='deepnessSensorRightValue' type='tel'
                          value={props.deepnessSensorRightValue != null && !isNaN(props.deepnessSensorRightValue)
                            ? props.deepnessSensorRightValue : '-'}
                          readOnly />
                </div>
              </div>
            </div>
            :
            null
          }
        </div>

        <div className='row borders'>
          <div className='column'>
            <label className='checkbox'>
              Lämpötila sensorit
              <input type='checkbox'
                    onChange={props.toggleTemperatureSensor} 
                    checked={props.temperatureSensor} />
            </label>
          </div>
          { props.temperatureSensor ? 
            <div className='row'>
              <div className='column'>
                <label htmlFor='temperatureLeftValue'>Vasen arvo (°C)</label>
                <input id='temperatureLeftValue' type='tel'
                       value={props.temperatureLeftValue || '-'}
                       readOnly/>
              </div>
              <div className='column'>
              <label htmlFor='temperatureMiddleValue'>Keski arvo (°C)</label>
                <input id='temperatureMiddleValue' type='tel'
                       value={props.temperatureMiddleValue || '-'}
                       readOnly/>
              </div>
              <div className='column'>
              <label htmlFor='temperatureRightValue'>Oikea arvo (°C)</label>
                <input id='temperatureRightValue' type='tel'
                       value={props.temperatureRightValue || '-'}
                       readOnly/>
              </div>
              <div className='column'>
              <label htmlFor='temperatureREMValue'>Kuumennetun alustan arvo (°C)</label>
                <input id='temperatureREMValue' type='tel'
                       value={props.temperatureREMValue || '-'}
                       readOnly/>
              </div>
            </div>
            :
            null
          }
        </div>
    </fieldset>
  );
}

const MassInput = props => {
  return (
    <fieldset>
      <legend>
        <h4>Levitystiedot</h4>
      </legend>
      <div>
        <div className='row borders'>
          <div className='column'>
            <label htmlFor='road'>Tie</label>
            <input id='road' type='text' value={props.road || ''} readOnly/>
          </div>
          <div className='column'>
            <label htmlFor='roadPart'>Tieosa</label>
            Edellinen: {props.lastPart || '-'}
            <input id='roadPart' type='tel'
                  value={props.startRoadPart}
                  onChange={props.changeState.bind(this, 'road_part', 'integer', 0)}
                  readOnly={props.autoGPS}
                  required/>
          </div>
          <div className='column'>
            <label htmlFor='pole'>Paalu</label>
            Edellinen: {props.lastPole || '-'}
            <input id='pole' type='tel'
                  value={props.startRoadPole}
                  onChange={props.changeState.bind(this, 'road_pole', 'integer', 0)} 
                  readOnly={props.autoGPS}
                  required/>
            <label className='checkbox'>
              Automattinen GPS
              <input type='checkbox'
                     onChange={props.changeState.bind(this, 'autoGPS', 'boolean', true)} 
                     checked={props.autoGPS} />
            </label>
          </div>
        </div>
        <div className='row borders'>
          <div className='column'>
            <DirectionSelect onChange={props.changeState} direction={props.direction}
                             last={props.lastDirection} />
          </div>
          <div className='column'>
            <RoadwaySelect id='lane' onChange={props.changeState} roadway={props.roadway}
                           last={props.lastRoadway} />
          </div>
          <div className='column'>
            <LaneSelect onChange={props.changeState} lane={props.lane}
                        last={props.lastLane}/>
          </div>
          { props.groove ?
            <div className='column'>
              <GrooveLocation ref={e => grooveLocation = e} lastValue={props.lastLocationOnRoad}
                              values={locationValues} />
            </div>
            :
            null
          }
        </div>
        <div className='row borders'>
          <div className='column'>
            <label htmlFor='truck'>Rekka</label>
            Päivän kuormat: {props.dailyCount || '-'} kpl
            { props.selectedLoad === null ?
              <TruckSelect required store={props.store}/>
            :
              <input id='truck' type='text' value={props.truckRegisterNumber}
                onFocus={props.resetTruck}
                onChange={props.resetTruck} /** onChange needed to remove react error message *//>
            }
          </div>
          <div className='column'>
            <label htmlFor='mass'>Kuorma (Tonnit) </label>
            Päivän: {props.dailyTruckMass || '-'} tonnia
            { props.selectedLoad === null ?
              <input id='mass' type='tel'
                     value={props.mass || ''}
                     onChange={props.changeState.bind(this, 'truckMass', 'float', 0.0)} required/>
            :
              <input id='mass' type='tel'
                     value={props.mass}
                     readOnly/>
            }
          </div>
        </div>
        <div className='row'>
          <div className='column'>
            <Loads/>
          </div>
          <div className='column'>
            <label htmlFor='temperature'>Lämpötila (°C)</label>
            Edellinen: {props.lastTemperature || '-'} °C
            <input id='temperature' type='tel'
                  value={props.temperature || ''}
                  onChange={props.changeState.bind(this, 'temperature', 'float', 0.0)}/>
          </div>
          <div className='column'>
            <label htmlFor='smallArea'>Pienalueet (m²)</label>
            Päivän: {props.dailySmallAreas || '-'} m²
            <input id='smallArea' type='tel'
                  value={props.smallAreas || ''}
                  onChange={props.changeState.bind(this, 'smallAreas', 'float', 0.0)}/>
          </div>
          <div className='column'>
            <label htmlFor='leveling'>Tasaus (Tonnit)</label>
            Päivän: {props.dailyLevelingMass || '-'} tonnia
            <input id='leveling' type='tel'
                  value={props.levelingMass || ''}
                  onChange={props.changeState.bind(this, 'levelingMass', 'float', 0.0)}/>
          </div>
        </div>
        <div className='row'>
          <div className='column'>
            <label htmlFor='attentions'>Huomiot</label>
            <input id='attentions' type='text' value={props.attentions || ''}
                  onChange={props.changeState.bind(this, 'attentions', 'string', '')}/>
          </div>
        </div>
        <div className='center row'>
          <div className='column'>
           { props.disableSubmit ?
            <div className='loader'/>
           :
            <button onClick={props.newMass}>
              Lisää kuorma
            </button>
           }
          </div>
          <div className='column'>
          { props.disableEndForPath ?
            <div className='loader'/>
           :
            <button onClick={props.makeEndForPath}>
              Tee lopetuspaalu
              </button>
          }
          </div>
          <div className='column'>
          { props.disableNewPath ?
            <div className='loader'/>
           :
            <button onClick={props.newPath} className='button-outline'>Jatka edellistä kuormaa</button>
          }
          </div>
        </div>
      </div>
    </fieldset>
  );
}

const CurrentLocation = props => {
  let accuracyColor;
  let accuracy = props.accuracy;

  if (accuracy > 20) accuracyColor = 'red';
  else if (accuracy > 10) accuracyColor = 'yellow';
  else if (accuracyColor != null) accuracyColor = 'green';

  if (accuracy >= 10000) accuracy = '-';

  return (
    <div>
      <h4 className='center'>Nykyinen sijainti</h4>
      <div className='row'>
        <div className='column'>
        {'Tie: '}
        {props.roadNumber || '-'}
        </div>
        <div className='column'>
        {'Tieosa: '}
        {props.roadPart || '-'}
        </div>
        <div className='column'>
        {'Paalu: '}
        {props.roadPole || '-'}
        </div>
      </div>
      <div className='row'>
        <div className='column'>
          {Math.round(props.latitude * 10000) / 10000 || '-'}
          {' , '}
          {Math.round(props.longitude * 10000) / 10000 || '-'}
        </div>
        <div className='column'>
        {'Päivitetty: '}
        {props.time || '-'}
        </div>
        <div className='column'>
        {'Tarkkuus: '}
        <span id={'location-accuracy-' + accuracyColor}>{accuracy || '-'} </span>m
        </div>
      </div>
    </div>
  );
}

const CurrentData = props => {
  return (
    <div>
      Päivän tiehen käytetty massa: <strong>{Math.round(props.dailyMass * 100) / 100 || '-'}</strong> tonnia
      <br/>
      Päivän kokonaisuus Kg / m²: <strong>{Math.round(props.dailyCurrentMassPerSquare * 100) / 100 || '-'}</strong>
      <br/>
      Kohteen tiehen käytetty massa: <strong>{Math.round(props.wholeMass * 100) / 100 || '-'}</strong> tonnia
      <br/>
      Kohteen kokonaisuus Kg / m²: <strong>{Math.round(props.wholeCurrentMassPerSquare * 100) / 100 || '-'}</strong>
    </div>
  );
}

const FiveLastMasses = props => {
  if (props.target == null) return null;

  if (props.masses.size === 0) return <p className='center'>Ei yhtään lisäystä</p>;

  if (props.loading) return <div className='loader'></div>;

  return (
    <div>
      <h4>5 viimeisintä massaa</h4>
      { props.masses.splice(0, props.masses.size - 5).map(mass => {
        const date = new Date(mass.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes());
        let header = [];
        let roadInfo = '(Ei paikka tietoja)';

        if (mass.get('paths').size === 1) {
          roadInfo = mass.get('paths').first().get('start_part') + ' / ' + mass.get('paths').first().get('start_distance') +
                     (mass.get('paths').first().get('end_part') ? (' - ' + mass.get('paths').first().get('end_part') + ' / ' +
                     mass.get('paths').first().get('end_distance')) : '');
        }
        else if (mass.get('paths').size > 1) {
          roadInfo = mass.get('paths').size + ' levitystä';
        }

        header.push(<span key={mass.get('id')}>{
                      time + ' | ' + mass.get('truck').get('register_number') + ' | ' +
                      roadInfo + ' | Massa: ' + mass.get('truck_mass') + ' | kg / m²: '}
                    </span>);
        const calculated = mass.get('calculated_kg_per_square') ? Math.round(mass.get('calculated_kg_per_square') * 100) / 100 : '-';
        let color;
        let colorAble = true;
        if (mass.get('paths').first()) {
          const wantedMassPerSquare = mass.get('paths').first().get('mass_per_square');

          if (mass.get('paths').size > 1) {
            const count = mass.get('paths').filter(path =>
              path.get('mass_per_square') === wantedMassPerSquare).size;
            if (count !== mass.get('paths').size) {
              colorAble = false;
            }
          }

          if (calculated !== '' && colorAble) {
            if (calculated > wantedMassPerSquare + 40 ||
                calculated < wantedMassPerSquare - 40) {
              color = 'red-text'
            }
            else if (calculated > wantedMassPerSquare + 20 ||
                    calculated < wantedMassPerSquare- 20) {
              color = 'yellow-text'
            }
          }
        }
        header.push(<span key={mass.get('id') + '-kg/m'} className={color}>
                      {calculated}
                    </span>);
        header.push(<span key={mass.get('id') + '-not_saved'}>
                      {mass.get('not_saved') ? ' (Ei palvelimella)' : ''}
                    </span>);
        let data = {
          'Pienalueet': mass.get('small_areas') || '-',
          'Tasoitus': mass.get('leveling_mass') || '-',
          'Huomiot': mass.get('attentions') || '-',
          'Lämpötila': mass.get('temperature') || '-',
          'Asema tieto': mass.get('load') != null ? 'Kyllä' : 'Ei'
        }

        if (mass.get('milling_deepness_left')) {
          data['Jyrsintäsyvyys (mm) oikea'] = mass.get('milling_deepness_right');
          data['Jyrsintäsyvyys (mm) vasen'] = mass.get('milling_deepness_left');
          data['Kuumennetun alustan lämpötila (°C)'] = mass.get('REM_temperature');
        }

        if (mass.get('location_on_road')) {
          data['Sijainti ajoradalla'] = locationValues['L' + mass.get('location_on_road')];
        }

        return (
          <div className='datafield' key={mass.get('id')}>
            <DataField header={header} data={data} class={mass.get('not_saved') ? 'yellow' : null}>
              <br/>
              { mass.get('paths').map((path, index) => (
                <p key={index}>
                  { mass.get('paths').size > 1 ?
                  <span>
                    <strong>{index + 1 + '. '}</strong>
                    {path.get('start_part') + ' / ' + path.get('start_distance') +
                    ' - ' + (path.get('end_part') ? (path.get('end_part') + ' / ' + path.get('end_distance')) : '')}
                  </span>
                  : null }
                  <strong>Ajorata:</strong> {' ' + path.get('roadway') + ' '}
                  <strong>Suunta:</strong> {' ' + path.get('direction') + ' '}
                  <strong>Kaista:</strong> {' ' + path.get('lane') + ' '}
                  { path.get('location_on_road') ?
                    <span>
                      <strong>Sijainti ajoradalla:</strong> {' ' + locationValues['L' + path.get('location_on_road')] + ' '}
                    </span>
                    : null
                  }
                  <strong>Leveys:</strong> {' ' + path.get('width') + ' m'}
                </p>
                ))
              }
              <div className='row center'>
                <div className='column'>
                  <button onClick={props.changeMass.bind(this, mass)}>
                    Muokkaa
                  </button>
                </div>
                { mass.get('paths').size > 1 ?
                  <div className='column'>
                    <button className='button-outline' onClick={props.removeLastPath.bind(this, mass)}>
                      Poista viimeisin levitys
                    </button>
                  </div>
                  : null
                }
                <div className='column'>
                  <button className='button-outline' onClick={mass.get('not_saved') ? 
                                                      props.removeLocalMass.bind(this, mass.get('id')) :
                                                      props.removeMass.bind(this, mass.get('id'))}>
                    Poista
                  </button>
                </div>
              </div>
            </DataField>
          </div>
        );
      })
      }
    </div>
  );
};

const TrackingInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Seurantatiedot</h4>
      </legend>
      <div className='center'>
        <button className='add-small-area-button' onClick={props.toggleAddSmallArea}>
          Lisää pienalue
        </button>
      </div>
      <FiveLastMasses masses={props.masses} target={props.target}
                      loading={props.loading} removeMass={props.removeMass}
                      removeLocalMass={props.removeLocalMass} removeLastPath={props.removeLastPath}
                      changeMass={props.changeMass} addSmallArea={props.addSmallArea} />
      <div className='row'>
        <div className='column'>
          <CurrentLocation roadNumber={props.roadNumber} roadPart={props.roadPart}
                           roadPole={props.roadPole} loading={props.loading}
                           accuracy={props.accuracy} time={props.time}
                           latitude={props.latitude} longitude={props.longitude} />
        </div>
        <div className='column'>
          <CurrentData dailyMass={props.dailyMass}
                       dailyCurrentMassPerSquare={props.dailyCurrentMassPerSquare}
                       wholeMass={props.wholeMass}
                       wholeCurrentMassPerSquare={props.wholeCurrentMassPerSquare} />
        </div>
      </div>
      <br/>
      <Graph masses={props.masses} resetCrosshairValue={props.resetCrosshairValue}
             crosshairValue={props.crosshairValue} setCrosshairValue={props.setCrosshairValue}
             graphData={props.graphData} graphData2={props.graphData2}
             graphWholeData={props.graphWholeData} />
    </fieldset>
  );
};

const Graph = props => {
  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>{props.crosshairValue[0].massPerSquare} kg / m²</span>
    </div>
    : null;

  const crosshair2 = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>{props.crosshairValue[0].wholeMassPerSquare} kg / m²</span>
    </div>
    : null;

  const ITEMS = [
      {title: 'Saatu', color: "blue", strokeStyle: "dashed"},
      {title: 'Tavoite', color: 'green', strokeDasharray: "dashed"},
    ];

  return (
    <div className='row'>
      <DiscreteColorLegend orientation="horizontal" width={300} items={ITEMS} />
      <div className='column'>
        <h4>Yksittäisen kuorman Kg / m²</h4>
        <XYPlot height={200} width={300} onMouseLeave={props.resetCrosshairValue}>
          <YAxis />
          <LineSeries color="blue" data={props.graphData}
                      onNearestX={props.setCrosshairValue} />
          <LineSeries color="green" data={props.graphData2} />
          <Crosshair values={props.crosshairValue}>
            {crosshair}
          </Crosshair>
        </XYPlot>
      </div>
      <div className='column'>
        <h4>Kohteen kokonais kg / m²</h4>
        <XYPlot height={200} width={300} onMouseLeave={props.resetCrosshairValue}>
          <YAxis />
          <LineSeries color="blue" data={props.graphWholeData}
                      onNearestX={props.setCrosshairValue} />
          <LineSeries color="green" data={props.graphData2} />
          <Crosshair values={props.crosshairValue}>
            {crosshair2}
          </Crosshair>
        </XYPlot>
      </div>
    </div>
  );
}

const AddSmallArea = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='add-small-area-modal'>
        <h4>Lisää pienalue</h4>
        <h5>Valitse kuorma</h5>
        Vihreä = todennäköisesti tästä kuormasta tehty pienalue
        <br/>
        Vaalean vihreä = melko todennäköisesti
        {
          props.masses.splice(0, props.masses.size - 5).map(mass => {
            const date = new Date(mass.get('date'));
            const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes());
            const calculated = mass.get('calculated_kg_per_square') ? Math.round(mass.get('calculated_kg_per_square') * 100) / 100 : '-';
            let color = '';
            let colorAble = true;
            if (mass.get('paths').first()) {
              const wantedMassPerSquare = mass.get('paths').first().get('mass_per_square');
      
              if (mass.get('paths').size > 1) {
                const count = mass.get('paths').filter(path =>
                  path.get('mass_per_square') === wantedMassPerSquare).size;
                if (count !== mass.get('paths').size) {
                  colorAble = false;
                }
              }
      
              if (calculated !== '' && colorAble) {
                if (calculated > wantedMassPerSquare + 40 ||
                    calculated < wantedMassPerSquare - 40) {
                  color = 'green'
                }
                else if (calculated > wantedMassPerSquare + 20 ||
                        calculated < wantedMassPerSquare - 20) {
                  color = 'light-green'
                }
              }
            }

            let selected = mass === props.selectedMass ? 'selected' : '';

            return (
              <div key={mass.get('id')} className={selected + ' mass-select ' + color}
                   onClick={props.selectMass.bind(null, mass)}>
                <div className='mass-select-item'>
                  {time}
                </div>
                <div className='mass-select-item'>
                  {mass.get('truck').get('register_number')}
                </div>
                <div className='mass-select-item'>
                  {mass.get('truck_mass')} t
                </div>
                <div className='mass-select-item'>
                  {calculated} kg / m²
                </div>
              </div>
            );
          })
        }
        <br/>
        <label htmlFor='smallArea'>Pinta-ala (m²)</label>
        <input id='smallArea' type='tel'
               value={props.addingSmallArea}
               onChange={props.changeState.bind(this, 'addingSmallArea', 'float', 0.0)}/>
        <div className='center row'>
          <div className='column'>
            <button onClick={props.addSmallArea}>Lisää</button>
          </div>
          <div className='column'>
            <button onClick={props.toggle} className='button-outline'>Peruuta</button>
          </div>
        </div>
      </div>
    </div>
  );
}


class MassNew extends React.Component {

  constructor(props) {
    super(props);

    this.watchID = null;

    const targetWidth = floatValue(localStorage.targetWidth, 0);

    this.SENSOR_TIME_AGO = 3;

    this.state = {
      roadway: 0,
      direction: 1,
      lane: 1,
      width: 0,
      massPerSquare: 100,
      thickness: 0,
      location_road_number: null,
      location_road_part: null,
      location_road_pole: null,
      latitude: null,
      longitude: null,
      accuracy: null,
      locationTime: null,
      road_part: 0,
      road_pole: 0,
      truckRegisterNumber: '',
      truckMass: 0,
      temperature: 0,
      smallAreas: 0,
      levelingMass: 0,
      attentions: '',
      load: null,
      dailyCount: 0,
      dailyLength: 0,
      dailyTruckMass: 0,
      dailyMass: 0,
      dailySmallAreas: 0,
      dailyLevelingMass: 0,
      dailyCurrentMassPerSquare: 0,
      loadingMasses: false,
      view: 0,
      removingMass: null,
      autoGPS: true,
      confirmed: false,
      addingSmallArea: '',
      traveledDistance: 0,
      calculatingMassPerSquares: false,
      crosshairValue: [],
      REM: false,
      groove: false,
      sensor: false,
      millingDeepnessRight: 0,
      millingDeepnessLeft: 0,
      REMTemperature: 0,
      paths: {},
      widthSensor: false,
      lengthBetweenWidthSensors: null,
      widthSensorValue: null,
      widthSensorLeftValue: null,
      widthSensorRightValue: null,
      deepnessSensor: false,
      deepnessOriginalSensorLeftTrueValue: null,
      deepnessOriginalSensorRightTrueValue: null,
      deepnessAfterSensorLeftTrueValue: null,
      deepnessAfterSensorRightTrueValue: null,
      deepnessSensorLeftValues: [],
      deepnessSensorRightValues: [],
      deepnessOriginalSensorLeftCalibrateValue: null,
      deepnessOriginalSensorRightCalibrateValue: null,
      deepnessAfterSensorLeftCalibrateValue: null,
      deepnessAfterSensorRightCalibrateValue: null,
      temperatureSensorLeftValues: [],
      temperatureSensorMiddleValues: [],
      temperatureSensorRightValues: [],
      temperatureSensorREMValues: [],
      mapPaths: [],
      targetWidth: targetWidth,
      setTargetWidth: targetWidth,
      sensorDevices: [],
      selectedSensorDevice: '',
      lastSelectedSensorDevice: null,
      lastSensortime: null,
      widthAlert: false,
      reverseLeftDeepness: 0,
      reverseRightDeepness: 0,
      deepnessCalibrationDate: null,
      deepnessCalibrationTime: null,
      deepnessLeftOriginalCalibration: 0,
      deepnessLeftAfterCalibration: 0,
      deepnessRightOriginalCalibration: 0,
      deepnessRightAfterCalibration: 0
    };

    this.changeState = this.changeState.bind(this);
    this.saveMass = this.saveMass.bind(this);
    this.sendSavedMasses = this.sendSavedMasses.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.useLocation = this.useLocation.bind(this);
    this.locationError = this.locationError.bind(this);
    this.onMakeEndForPath = this.onMakeEndForPath.bind(this);
    this.makeEndForPath = this.makeEndForPath.bind(this);
    this.newPath = this.newPath.bind(this);
    this.updateLocallyLastPath = this.updateLocallyLastPath.bind(this);
    this.confirmRoadInfo = this.confirmRoadInfo.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goChangeMass = this.goChangeMass.bind(this);
    this.clearChangeMass = this.clearChangeMass.bind(this);
    this.changeLocalMass = this.changeLocalMass.bind(this);
    this.changeMass = this.changeMass.bind(this);
    this.confirmRemoveMass = this.confirmRemoveMass.bind(this);
    this.removeMass = this.removeMass.bind(this);
    this.confirmRemoveLocalMass = this.confirmRemoveLocalMass.bind(this);
    this.removeLocalMass = this.removeLocalMass.bind(this);
    this.confirmRemoveLastPath = this.confirmRemoveLastPath.bind(this);
    this.removeLastPath = this.removeLastPath.bind(this);
    this.getDailyData = this.getDailyData.bind(this);
    this.getLastMassData = this.getLastMassData.bind(this);
    this.resetTruck = this.resetTruck.bind(this);
    this.toggleAccuracyFixer = this.toggleAccuracyFixer.bind(this);
    this.toggleAddSmallArea = this.toggleAddSmallArea.bind(this);
    this.addSmallArea = this.addSmallArea.bind(this);
    this.selectMass = this.selectMass.bind(this);
    this.setLocationWatchAgain = this.setLocationWatchAgain.bind(this);
    this.setCrosshairValue = this.setCrosshairValue.bind(this);
    this.resetCrosshairValue = this.resetCrosshairValue.bind(this);
    this.toggleSensors = this.toggleSensors.bind(this);
    this.toggleWidthSensor = this.toggleWidthSensor.bind(this);
    this.sendWidthSensorValues = this.sendWidthSensorValues.bind(this);
    this.toggleDeepnessSensor = this.toggleDeepnessSensor.bind(this);
    this.sendDeepnessSensorValues = this.sendDeepnessSensorValues.bind(this);
    this.calibrateDeepnessSensors = this.calibrateDeepnessSensors.bind(this);
    this.toggleTemperatureSensor = this.toggleTemperatureSensor.bind(this);
    this.sendTemperatureSensorValues = this.sendTemperatureSensorValues.bind(this);
    this.acceptTargetWidth = this.acceptTargetWidth.bind(this);
    this.createNotice = this.createNotice.bind(this);
    this.updateNotice = this.updateNotice.bind(this);
    this.confirmNoticeText = this.confirmNoticeText.bind(this);
    this.getSensorDevices = this.getSensorDevices.bind(this);
    this.getSensorValues = this.getSensorValues.bind(this);
    this.updateSensorValues = this.updateSensorValues.bind(this);
  }

  componentDidMount() {
    if (localStorage['login'] == null) {
      return;
    }
    
    if (typeof(Storage) !== 'undefined') {
      let road = null;

      if (localStorage.site != null) {
        road = integerValue(localStorage.site['id'], null);
      }

      this.setState({
        roadway: integerValue(localStorage.roadway, 1),
        lane: integerValue(localStorage.lane, 1),
        direction: integerValue(localStorage.direction, 1),
        width: floatValue(localStorage.width, 0),
        massPerSquare: floatValue(localStorage.massPerSquare, 100),
        thickness: floatValue(localStorage.thickness, 0),
        road: road,
        road_part: integerValue(localStorage.road_part, 0),
        road_pole: integerValue(localStorage.road_pole, 0),
        truckMass: floatValue(localStorage.truckMass, 0),
        smallAreas: floatValue(localStorage.smallAreas, 0),
        temperature: floatValue(localStorage.temperature, 0),
        levelingMass: integerValue(localStorage.levelingMass, 0),
        attentions: localStorage.attentions ? localStorage.attentions : '',
        autoGPS: localStorage.autoGPS === 'false' ? false : true,
        REM: localStorage.REM === 'true' ? true : false,
        groove: localStorage.groove === 'true' ? true : false,
        sensor: localStorage.sensor === 'true' ? true : false,
        lengthBetweenWidthSensors: floatValue(localStorage.lengthBetweenWidthSensors, 0),
        millingDeepnessRight: floatValue(localStorage.millingDeepnessRight, 0),
        millingDeepnessLeft: floatValue(localStorage.millingDeepnessLeft, 0),
        REMTemperature: floatValue(localStorage.REMTemperature, 0),
        deepnessOriginalSensorLeftCalibrateValue: floatValue(localStorage.deepnessOriginalSensorLeftCalibrateValue, 0),
        deepnessOriginalSensorRightCalibrateValue: floatValue(localStorage.deepnessOriginalSensorRightCalibrateValue, 0),
        deepnessAfterSensorLeftCalibrateValue: floatValue(localStorage.deepnessAfterSensorLeftCalibrateValue, 0),
        deepnessAfterSensorRightCalibrateValue: floatValue(localStorage.deepnessAfterSensorRightCalibrateValue, 0),
        widthSensor: localStorage.widthSensor === 'true' ? true : false,
        deepnessSensor: localStorage.deepnessSensor === 'true' ? true : false,
        temperatureSensor: localStorage.temperatureSensor === 'true' ? true : false,
        selectedSensorDevice: localStorage.selectedSensorDevice ? localStorage.selectedSensorDevice : '',
        deepnessCalibrationDate: localStorage.deepnessCalibrationDate ? localStorage.deepnessCalibrationDate : '',
        deepnessCalibrationTime: localStorage.deepnessCalibrationTime ? localStorage.deepnessCalibrationTime : '',
        reverseLeftDeepness: localStorage.reverseLeftDeepness ? integerValue(localStorage.reverseLeftDeepness, 0) : 0,
        reverseRightDeepness: localStorage.reverseRightDeepness ? integerValue(localStorage.reverseRightDeepness, 0) : 0
      });

      this.getSensorDevices();

      if (localStorage.sensor === 'true') {
        this.toggleSensors();
      }

      this.sendSavedWidths();
      this.sendSavedDeepnesses();
      this.sendSavedTemperatures();
    }

    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket('/data/mass');
      this.socket.onmessage = async function(e) {
        if (this.props.selectedConstructionSite == null ||
            this.state.loadingMasses) {
          return;
        }

        const data = JSON.parse(e.data);

        if (data['operation'] === 'create') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.constructionSiteId) {
              return;
            }
          }
          else {
            const exist = this.props.masses.find(mass => mass.get('paths_id') === data.model.paths_id);
            if (!exist) {
              return;
            }
          }

          this.getMasses(this.props.selectedConstructionSite.get('id'));
        }
        else if (data['operation'] === 'update') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site.id) {
              return;
            }
            
            if (data.model.paths_id) {
              data.model.paths = await this.getPaths(data.model.paths_id);
            }

            this.props.changeMass(data.model);
          }
          else {
            const exist = this.props.masses.find(mass => mass.get('paths_id') === data.model.paths_id);
            if (exist) {
              this.getMasses(this.props.selectedConstructionSite.get('id'));
            }
          }
        }
        else if (data['operation'] === 'delete') {
          this.props.removeMassById(data.model);
        }
      }.bind(this)
    }

    if (!navigator.geolocation) {
      this.props.showMessage('Virhe', 
        'Paikan haku ei ole tuettu tällä selaimella eli et voi saada automaattista sijaintia', 'Error');
      return;
    }

    this.watchID = navigator.geolocation.watchPosition(this.setLocation, this.locationError, 
      {enableHighAccuracy: true});
  }

  async componentDidUpdate(lastProps, lastState) {
    if (lastState.deepnessCalibrationDate !== this.state.deepnessCalibrationDate ||
        lastState.deepnessCalibrationTime !== this.state.deepnessCalibrationTime) {
      this.setCalibrationValues();
    }

    if (lastState.reverseLeftDeepness !== this.state.reverseLeftDeepness ||
        lastState.deepnessLeftOriginalCalibration !== this.state.deepnessLeftOriginalCalibration ||
        lastState.deepnessLeftAfterCalibration !== this.state.deepnessLeftAfterCalibration ||
        lastState.deepnessRightOriginalCalibration !== this.state.deepnessRightOriginalCalibration ||
        lastState.deepnessRightAfterCalibration !== this.state.deepnessRightAfterCalibration) {
      this.calibrateDeepnessSensors();
    }

    if (lastState.selectedSensorDevice !== this.state.selectedSensorDevice &&
        this.state.selectedSensorDevice !== '') {
      this.setState({ lastSelectedSensorDevice: this.state.selectedSensorDevice });
    }

    if (lastProps.selectedConstructionSite != null &&
        this.props.selectedConstructionSite == null &&
        this.state.view !== 0) {
      this.setState({ view: 0 });
    }

    if (!this.state.autoGPS && lastState.autoGPS) {
      this.useLocation(true);
    }

    if (this.props.masses !== lastProps.masses) {
      if (!lastState.calculatingMassPerSquares) {
        this.calculateMassPerSquares(this.props.masses);
      }
      this.getDailyData(this.props.masses);
      this.getLastMassData(this.props.masses);
      this.getMapPaths(this.props.masses, this.props.selectedConstructionSite ?
        this.props.selectedConstructionSite.get('id') : null);
    }

    if (this.props.selectedLoad != null && this.props.selectedLoad !== lastProps.selectedLoad) {
      this.setState({
        truckRegisterNumber: this.props.selectedLoad.get('truck'),
        truckMass: this.props.selectedLoad.get('total_mass'),
        load: this.props.selectedLoad.get('id')
      });
    }

    if (this.props.selectedContract === null || this.props.selectedConstructionSite === null) {
      this.props.clearMasses();
      return;
    }

    if (this.props.selectedTruck != null && this.props.selectedTruck !== lastProps.selectedTruck) {
      this.setState({
        truckRegisterNumber: this.props.selectedTruck.get('register_number'),
        truckMass: this.props.selectedTruck.get('default_mass'),
        load: null
      });
    }
    
    if (this.props.selectedConstructionSite == null ||
        this.props.selectedConstructionSite === lastProps.selectedConstructionSite) {
      return;
    }

    this.getMasses(this.props.selectedConstructionSite.get('id'));
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchID);
    if (this.socket != null) this.socket.close();
    clearInterval(this.sensorTimer);
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }
    
    this.setState({[propertyName]: value});

    if (typeof(Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  async setLocation(position) {
    let latitude = null;
    let longitude = null;
    let accuracy = null;

    if (position.coords) {
      if (this.state.sensor) {
        return;
      }

      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      accuracy = Math.ceil(position.coords.accuracy);
    }
    else {
      latitude = position.lat;
      longitude = position.lon;
    }

    const time = new Date();

    this.setState({
      latitude: latitude,
      longitude: longitude,
      accuracy: accuracy,
      locationTime: paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds())
    });

    let road;

    if (this.props.selectedConstructionSite != null) {
     road = this.props.selectedConstructionSite.get('road_number');
    }

    const converted = toETRSTM35FIN(latitude, longitude);
    const data = await getRoadData(converted.y, converted.x, accuracy, road);

    if (data != null) {
      this.setState({
        locationError: false,
        location_road_number: data.road,
        location_road_part: data.part,
        location_road_pole: data.distance
      }, () => {
        this.useLocation(this.state.autoGPS);
      });
    }
    else {
      const data = await getRoadData(converted.y, converted.x, accuracy);

      if (data != null) {
        this.setState({
          locationError: false,
          location_road_number: data.road,
          location_road_part: data.part,
          location_road_pole: data.distance
        });
      }
      else {
        this.setState({
          locationError: true,
          location_road_number: null,
          location_road_part: null,
          location_road_pole: null
        });
      }
    }
  }

  async useLocation(autoGPS) {
    const roadNumber = this.state.location_road_number;

    if (this.props.selectedConstructionSite &&
        this.props.selectedConstructionSite.get('road_number') !== roadNumber) {
      return;
    }

    if (this.state.lastPath != null && !this.state.lastPath.get('end_part')) {
      this.setState({
        traveledDistance: await calculateRoadDistance(roadNumber, this.state.lastPath.get('start_part'), this.state.lastPath.get('start_distance'),
                                                      roadNumber, this.state.location_road_part, this.state.location_road_pole)
      });
    }

    if (!autoGPS) return;

    this.setState({
      road_part: this.state.location_road_part || '',
      road_pole: this.state.location_road_pole || ''
      }, function() {
        if (this.state.showAccuracyFixer && this.state.accuracy < 20) {
          this.state.accuracySubmit();
        }
        localStorage.road_part = this.state.road_part;
        localStorage.road_pole = this.state.road_pole;
    });
  }

  locationError(err) {
    if (this.state.sensor) {
      return;
    }
    this.setState({ locationError: true });
  }

  confirmRoadInfo(callback) {
    this.setState({
      confirmed: true
    }, () => callback());
  }

  async onMakeEndForPath() {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Kohdetta ei ole valittu', 'Warning');
      return;
    }

    if (this.state.road_part === 0 || this.state.road_part === '') {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }

    if (this.state.road_pole === null || this.state.road_pole === '') {
      this.props.showNotice('Paalua ei ole annettu', 'Warning');
      return;
    }

    if (this.props.masses.last() === undefined) {
      this.props.showNotice('Tarvitaan ainakin yksi kuorma', 'Warning');
      return;
    }

    if (!this.state.confirmed) {
      if (!this.state.showAccuracyFixer) {
        if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
          this.toggleAccuracyFixer(this.onMakeEndForPath);
          return;
        }
      }
      else {
        this.setState({
          showAccuracyFixer: false
        });
      }
    }

    const roadNumber = this.props.selectedConstructionSite.get('road_number');
    const roadPart = this.state.road_part;
    const roadDistance = this.state.road_pole;

    if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
      this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
                            ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.onMakeEndForPath));
      return;
    }

    this.setState({
      confirmed: false,
      fromEnding: false
    });

    this.props.showConfirm('Tehdäänkö lopetes paalu viimeisimpään kuormaan? (' +
      this.state.lastPath.get('start_part') + ' / ' + this.state.lastPath.get('start_distance') + ' - ' +
      this.state.road_part + ' / ' + this.state.road_pole + ')', this.makeEndForPath);
  }

  async makeEndForPath(notice=true) {
    this.setState({ disableEndForPath: true });

    const path = {
      end_part: this.state.road_part,
      end_distance: this.state.road_pole,
    };

    const lastMass = this.props.masses.last();

    if (!lastMass.get('not_saved')) {
      try {
        const data = await fetch('/path/' + this.state.lastPath.get('id'), 'PATCH', path);
        if (notice) {
          this.props.showNotice('Lopetus paalu tehty', 'Ok');
        }
        this.props.updateLastPath(lastMass.get('id'), data);
      } catch(error) {
        await this.updateLocallyLastPath(path.end_part, path.end_distance);
        if (notice) {
          this.props.showNotice('Lopetus paalu tallennettu paikallisesti', 'Warning');
        }
      }
    }
    else {
      await this.updateLocallyLastPath(path.end_part, path.end_distance);
      if (notice) {
        this.props.showNotice('Lopetus paalu tehty', 'Ok');
      }
    }

    this.sendSensorData();

    this.setState({ disableEndForPath: false });
  }

  async newPath() {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Kohdetta ei ole valittu', 'Warning');
      return;
    }

    if (this.state.lastPath == null) {
      this.props.showNotice('Aikasempaa kuormaa ei ole', 'Warning');
      return;
    }

    if (this.state.width === "0" || this.state.width === "" || this.state.width === 0) {
      this.props.showNotice('Tien leveyttä ei ole annettu', 'Warning');
      return;
    }

    if (this.state.road_part === 0 || this.state.road_part === '') {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }

    if (this.state.road_pole === null || this.state.road_pole === '') {
      this.props.showNotice('Paalua ei ole annettu', 'Warning');
      return;
    }

    if (this.state.groove && grooveLocation.state.location == null) {
      this.props.showNotice('Sijaintia ajoradalla ei ole valittu', 'Warning');
      return;
    }

    if (!this.state.confirmed) {
      if (!this.state.showAccuracyFixer) {
        if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
          this.toggleAccuracyFixer(this.newPath);
          return;
        }
      }
      else {
        this.setState({
          showAccuracyFixer: false
        });
      }
    }

    const roadNumber = this.props.selectedConstructionSite.get('road_number');
    const roadPart = this.state.road_part;
    const roadDistance = this.state.road_pole;

    try {
      if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
                              ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.newPath));
        return;
      }
    } catch(error) {
      console.log(error);
    }

    this.setState({
      confirmed: false,
      disableNewPath: true
    });

    let date = new Date();
    const timezoneOffset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() - timezoneOffset);

    let path = {
      paths_id: this.state.lastPath.get('paths_id'),
      road: roadNumber,
      start_part: roadPart,
      start_distance: roadDistance,
      width: parseFloat(this.state.width),
      roadway: this.state.roadway,
      lane: this.state.lane,
      direction: this.state.direction,
      date: date.toISOString().replace('Z', ''),
      mass_per_square: parseFloat(this.state.massPerSquare)
    };

    if (this.state.groove) {
      path.location_on_road = parseInt(grooveLocation.state.location.substring(1), 10);
    }

    if (!this.state.lastPath.get('end_part')) {
      this.makeEndForPath(false)
    }

    fetch('/path', 'POST', path).then(async data => {
      this.props.showNotice('Uusi aloitus tehty', 'Ok');
      this.props.addPathToLastMass(data);
    }).catch(error => {
      this.saveNewPathToLastMass(path);
      this.props.showNotice('Uusi aloitus tallennettu paikallisesti', 'Warning');
    }).then(() => {
      this.setState({ disableNewPath: false });
    });
  }

  async updateLocallyLastPath(endPart, endDistance) {
    let length = 0;

    if (endPart === this.state.lastPath.get('start_part')) {
      length = endDistance - this.state.lastPath.get('start_distance');
      if (length < 0) {
        length *= -1;
      }
    }
    else {
      length = await calculateRoadDistance(this.state.lastPath.get('road'),
                                          this.state.lastPath.get('start_part'),
                                          this.state.lastPath.get('start_distance'),
                                          this.state.lastPath.get('road'),
                                          endPart, endDistance);
    }

    const updatedPath = {
      id: this.state.lastPath.get('id'),
      road: this.state.lastPath.get('road'),
      start_part: this.state.lastPath.get('start_part'),
      start_distance: this.state.lastPath.get('start_distance'),
      end_part: endPart,
      end_distance: endDistance,
      length: length,
      width: this.state.lastPath.get('width'),
      area: length * this.state.lastPath.get('width'),
      lane: this.state.lastPath.get('lane'),
      roadway: this.state.lastPath.get('roadway'),
      direction: this.state.lastPath.get('direction'),
      location_on_road: this.state.lastPath.get('location_on_road'),
      date: this.state.lastPath.get('date'),
      mass_per_square: this.state.lastPath.get('mass_per_square')
    };

    const lastMass = this.props.masses.last();

    if (!lastMass.get('not_saved')) {
      if (localStorage['updatedMasses'] == null) {
        localStorage['updatedMasses'] = JSON.stringify([]);
      }

      let masses = JSON.parse(localStorage['updatedMasses']);
      const existingIndex = masses.findIndex(mass => mass.id === lastMass.get('id'));

      if (existingIndex !== -1) {
        let paths = masses[existingIndex].paths;
        paths[paths.length - 1] = updatedPath;
      }
      else {
        let paths = [];

        lastMass.get('paths').forEach(path => {
          paths.push({
            id: path.get('id'),
            road: path.get('road'),
            start_part: path.get('start_part'),
            start_distance: path.get('start_distance'),
            end_part: path.get('end_part'),
            end_distance: path.get('end_distance'),
            length: path.get('length'),
            width: path.get('width'),
            area: path.get('area'),
            lane: path.get('lane'),
            roadway: path.get('roadway'),
            direction: path.get('direction'),
            location_on_road: path.get('location_on_road'),
            mass_per_square: this.state.lastPath.get('mass_per_square')
          });
        });

        paths[paths.length - 1] = updatedPath;

        const mass = {
          id: lastMass.get('id'),
          paths: paths
        }

        masses.push(mass);
      }

      localStorage['updatedMasses'] = JSON.stringify(masses);
    }
    else {
      let masses = JSON.parse(localStorage['savedMasses']);
      const index = masses.findIndex(mass => mass.id === lastMass.get('id'));
      let paths = masses[index].paths;
      paths[paths.length - 1] = updatedPath;
      localStorage['savedMasses'] = JSON.stringify(masses);
    }

    this.props.updateLastPath(lastMass.get('id'), updatedPath);
  }

  saveNewPathToLastMass(path) {
    const lastMass = this.props.masses.last();

    if (!lastMass.get('not_saved')) {
      if (localStorage['updatedMasses'] == null) {
        localStorage['updatedMasses'] = JSON.stringify([]);
      }

      let masses = JSON.parse(localStorage['updatedMasses']);
      const existingIndex = masses.findIndex(mass => mass.id === lastMass.get('id'));

      if (existingIndex !== -1) {
        let paths = masses[existingIndex].paths;
        paths.push(path);
      }
      else {
        let paths = [];

        lastMass.get('paths').forEach(path => {
          paths.push({
            id: path.get('id'),
            road: path.get('road'),
            start_part: path.get('start_part'),
            start_distance: path.get('start_distance'),
            end_part: path.get('end_part'),
            end_distance: path.get('end_distance'),
            length: path.get('length'),
            width: path.get('width'),
            area: path.get('area'),
            lane: path.get('lane'),
            roadway: path.get('roadway'),
            direction: path.get('direction'),
            location_on_road: path.get('location_on_road'),
          });
        });

        paths.push(path);

        const mass = {
          id: lastMass.get('id'),
          paths: paths
        }

        masses.push(mass);
      }

      localStorage['updatedMasses'] = JSON.stringify(masses);
    }
    else {
      let masses = JSON.parse(localStorage['savedMasses']);
      const index = masses.findIndex(mass => mass.id === lastMass.get('id'));
      let paths = masses[index].paths;
      paths.push(path);
      localStorage['savedMasses'] = JSON.stringify(masses);
    }

    this.props.addPathToLastMass(path);
  }

  async saveMass(mass, path) {
    if (typeof(Storage) !== 'undefined') {
      if (localStorage['savedMasses'] == null) {
        localStorage['savedMasses'] = JSON.stringify([]);
      }

      mass.id = Date.now();
      mass.not_saved = true;
      mass.paths = [path];
      
      let masses = JSON.parse(localStorage['savedMasses']);
      masses.push(mass);
      localStorage['savedMasses'] = JSON.stringify(masses);
    }
    else {
      this.props.showMessage('Virhe', 'Massaa ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
    }
  }

  async sendSavedMasses() {
    let masses = JSON.parse(localStorage['savedMasses']);
    let newMasses = masses.slice();

    for (let index in masses) {
      const mass = masses[index];

      try {
        const newMass = await fetch('/masses', 'POST', mass);

        for (let i in mass.paths) {
          let path = mass.paths[i];
          path.paths_id = newMass.paths_id;

          try {
            await fetch('/path', 'POST', path);
          } catch(err) {
            
          }
        }

        newMasses.splice(newMasses.findIndex(m => m['id'] === mass['id']), 1);
      } catch(err) {
        console.log(err);
      }
    }
    
    localStorage['savedMasses'] = JSON.stringify(newMasses);
  }

  async sendUpdatedMasses() {
    let masses = JSON.parse(localStorage['updatedMasses']);
    let newMasses = masses.slice();

    for (let index in masses) {
      const mass = masses[index];

      try {
        await fetch('/masses/' + mass.id, 'PATCH', mass);

        for (let i in mass.paths) {
          let path = mass.paths[i];

          if (path.id) {
            await fetch('/path/' + path.id, 'PATCH', path);
          }
          else {
            await fetch('/path', 'POST', path);
          }
        }

        newMasses.splice(newMasses.findIndex(newMass => newMass['id'] === mass['id']), 1);
      } catch(err) {
        console.log(err);
      }
    }

    localStorage['updatedMasses'] = JSON.stringify(newMasses);
  }

  async onSubmit() {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Kohdetta ei ole valittu', 'Warning');
      return;
    }

    if (this.state.width === "0" || this.state.width === "" || this.state.width === 0) {
      this.props.showNotice('Tien leveyttä ei ole annettu', 'Warning');
      return;
    }

    if (this.state.massPerSquare === "0" || this.state.massPerSquare === "") {
      this.props.showNotice('Tavoite kg / m² ei ole annettu', 'Warning');
      return;
    }

    if (this.state.road_part === 0 || this.state.road_part === '') {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }

    if (this.state.road_pole === null || this.state.road_pole === '') {
      this.props.showNotice('Paalua ei ole annettu', 'Warning');
      return;
    }

    if (this.state.truckRegisterNumber === '' && this.props.selectedTruck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    if (this.state.truckMass === 0) {
      this.props.showNotice('Kuormaa ei ole annettu', 'Warning');
      return;
    }

    if (!this.state.deepnessSensor && this.state.REM) {
      if (!this.state.deepnessSensor) {
        if (this.state.millingDeepnessRight === 0) {
          this.props.showNotice('Jyrsintä syvyyttä (Oikea) ei ole annettu', 'Warning');
          return;
        }
        if (this.state.millingDeepnessLeft === 0) {
          this.props.showNotice('Jyrsintä syvyyttä (Vasen) ei ole annettu', 'Warning');
          return;
        }
      }
      if (!this.state.temperatureSensor && this.state.REMTemperature === 0) {
        this.props.showNotice('Kuumennetun alustan lämpötilaa ei ole annettu', 'Warning');
        return;
      }
    }

    if (this.state.groove && grooveLocation.state.location == null) {
      this.props.showNotice('Sijaintia ajoradalla ei ole valittu', 'Warning');
      return;
    }

    if (!this.state.confirmed) {
      if (!this.state.showAccuracyFixer) {
        if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
          this.toggleAccuracyFixer(this.onSubmit);
          return;
        }
      }
      else {
        this.setState({
          showAccuracyFixer: false
        });
      }
    }

    const roadNumber = this.props.selectedConstructionSite.get('road_number');
    const roadPart = this.state.road_part;
    const roadDistance = this.state.road_pole;

    try {
      if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
                              ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.onSubmit));
        return;
      }
    } catch(error) {
      console.log(error);
    }

    if (this.state.lastPath) {
      try {
        if (!this.state.confirmed && !this.state.lastPath.get('end_part')) {
          const lastPart = this.state.lastPath.get('start_part');
          const lastDistance = this.state.lastPath.get('start_distance');
          const distance = await calculateRoadDistance(roadNumber, lastPart, lastDistance, roadNumber, roadPart, roadDistance);
          const lastMass = this.props.masses.last();
          const massPerSquare = lastMass.get('mass') * 1000 / (distance * this.state.lastPath.get('width'));

          if (massPerSquare <= this.state.lastPath.get('mass_per_square') - 20) {
            this.props.showConfirm('Viimeisellä levityksellä ei ole lopetus paalua, joten sen lopetus paaluksi tulee tämän kuorman aloitus. '
                                    + lastPart + '/' + lastDistance + ' - ' + roadPart + '/' + roadDistance + '. Haluatko jatkaa?',
            this.confirmRoadInfo.bind(null, this.onSubmit));
            return;
          }
        }
      } catch(error) {
        console.log(error);
      }
    }

    this.setState({
      confirmed: false,
      disableSubmit: true
    });

    let date = new Date();
    const timezoneOffset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() - timezoneOffset);

    let mass = {
        constructionSiteId: this.props.selectedConstructionSite.get('id'),
        truck_mass: parseFloat(this.state.truckMass),
        thickness: parseFloat(this.state.thickness),
        truckId: this.props.selectedTruck ? this.props.selectedTruck.get('id') : null,
        truck: {register_number: this.state.truckRegisterNumber || null},
        temperature: parseFloat(this.state.temperature) || null,
        small_areas: parseFloat(this.state.smallAreas) || 0,
        leveling_mass: parseFloat(this.state.levelingMass) || 0,
        attentions: this.state.attentions,
        load: this.state.load,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        weather_temperature: this.weather.state.temperature,
        weather_humidity : this.weather.state.humidity,
        date: date.toISOString().replace('Z', ''),
    };

    if (this.state.REM) {
      if (!this.state.deepnessSensor) {
        mass.milling_deepness_right = parseFloat(this.state.millingDeepnessRight);
        mass.milling_deepness_left = parseFloat(this.state.millingDeepnessLeft);
      }
      else {
        mass.milling_deepness_right = this.state.deepnessSensorLeftValue;
        mass.milling_deepness_left = this.state.deepnessSensorRightValue;
      }

      if (!this.state.temperatureSensor) {
        mass.REM_temperature = parseFloat(this.state.REMTemperature);
      }
      else {
        mass.REM_temperature = this.state.temperatureSensorREMValue;
      }
    }

    let path = {
      road: roadNumber,
      start_part: roadPart,
      start_distance: roadDistance,
      width: parseFloat(this.state.width),
      roadway: this.state.roadway,
      lane: this.state.lane,
      direction: this.state.direction,
      date: date.toISOString().replace('Z', ''),
      mass_per_square: parseFloat(this.state.massPerSquare)
    };

    if (this.state.groove) {
      path.location_on_road = parseInt(grooveLocation.state.location.substring(1), 10);
    }

    let newMass;
    
    try {
      newMass = await fetch('/masses', 'POST', mass);
    } catch(error) {
      await this.saveMass(mass, path);
      mass.date = mass.date + 'Z';
    }

    if (this.state.lastPath != null && !this.state.lastPath.get('end_part')) {
      await this.makeEndForPath(false);
    }

    if (newMass != null) {
      path.paths_id = newMass.paths_id;

      try {
        await fetch('/path', 'POST', path);
      } catch(error) {
        this.saveNewPathToLastMass(path);
      }
    }

    this.props.showNotice('Massa lisätty', 'Ok');
    this.props.selectTruck(null);
    this.props.selectLoad(null);

    this.setState({
      load: null,
      truckRegisterNumber: '',
      truckMass: 0,
      smallAreas: 0,
      levelingMass: 0,
      attentions: '',
      autoGPS: true,
      temperature: null
    });

    localStorage.removeItem("truck");
    localStorage.removeItem("truckMass");
    localStorage.removeItem("temperature");
    localStorage.removeItem("smallAreas");
    localStorage.removeItem("levelingMass");
    localStorage.removeItem("attentions");
    
    await this.getMasses(this.props.selectedConstructionSite.get('id'));

    this.setState({
      disableSubmit: false
    });
  }

  calculateMassPerSquares(masses) {
    this.setState({
      calculatingMassPerSquares: true
    }, () => {
      let graphData = [];
      let graphData2 = [];
      let graphWholeData = [];
      let currentWholeMass = 0;
      let currentWholeArea = 0;
      let currentCount = 0;
      let calculatedMasses = [];

      masses.forEach((mass, index) => {
        let paths = mass.get('paths');
        let massWholeArea = 0;
        let massWholeLength = 0;

        paths.forEach(path => {
          if (path.get('area')) {
            massWholeArea += path.get('area');
            massWholeLength += path.get('length');
          }
        });

        if (massWholeArea !== 0) {
          let weightedSumMassPerSquare = 0;

          paths.forEach((path, i) => {
            if (path.get('area')) {
              const ratio = path.get('area') / massWholeArea;
              weightedSumMassPerSquare += path.get('mass_per_square') * ratio;

              const estimatedMass = path.get('area') / massWholeArea * mass.get('mass');
              path = path.set('estimated_mass', estimatedMass);
              path = path.set('calculated_kg_per_square', estimatedMass * 1000 / path.get('area'));
              paths = paths.set(i, path);
            }
          });

          mass = mass.set('calculated_kg_per_square', mass.get('mass') * 1000 / massWholeArea);
          graphData.push({x: currentCount, y: mass.get('calculated_kg_per_square')});
          graphData2.push({x: currentCount, y: weightedSumMassPerSquare});
          currentWholeMass += mass.get('mass');
          currentWholeArea += massWholeArea;
          currentCount++;
          graphWholeData.push({x: currentCount - 1, y: currentWholeMass * 1000 / currentWholeArea});
          calculatedMasses.push(mass);

          mass = mass.set('paths', paths);
          mass = mass.set('length', massWholeLength);
          mass = mass.set('area', massWholeArea);
          masses = masses.set(index, mass);
        }
      });

      this.props.addMasses(masses);

      this.setState({
        calculatingMassPerSquares: false,
        graphData: graphData,
        graphData2: graphData2,
        graphWholeData: graphWholeData,
        calculatedMasses: calculatedMasses,
      });
    });

  }

  async getMasses(constructionSite) {
    this.setState({ loadingMasses: true });
    let allPaths = [];
    let masses = [];

    try {
      allPaths = await fetch('/paths/mass/site/' + constructionSite);

      if (typeof(Storage) !== 'undefined') {
        if (localStorage['savedMasses'] != null &&
          JSON.parse(localStorage['savedMasses']).length !== 0) {
          await this.sendSavedMasses();
        }
        if (localStorage['updatedMasses'] != null &&
            JSON.parse(localStorage['updatedMasses']).length !== 0) {
          await this.sendUpdatedMasses();
        }

        allPaths = await fetch('/paths/mass/site/' + constructionSite);
      }

      let data = await fetch('/masses?site=' + constructionSite);

      for (let index in data) {
        let mass = data[index];
        if (mass.paths_id) {
          try {
            const paths = allPaths.filter(path => path.paths_id === mass.paths_id);
            mass.paths = paths;
          } catch(error) {
            mass.paths = [];
          }
        }
        else {
          mass.paths = [];
        }
      }

      if (localStorage['savedMasses'] != null && JSON.parse(localStorage['savedMasses']).length !== 0) {
        const savedMasses = JSON.parse(localStorage['savedMasses']).filter(
          mass => mass.constructionSiteId === this.props.selectedConstructionSite.get('id'));
        data = data.concat(savedMasses);
      }

      if (localStorage['updatedMasses'] != null && JSON.parse(localStorage['updatedMasses']).length !== 0) {
        const updatedMasses = JSON.parse(localStorage['updatedMasses']).filter(
          mass => mass.constructionSiteId === this.props.selectedConstructionSite.get('id'));
        updatedMasses.forEach(mass => {
          const index = data.findIndex(d => d.id === mass.id);
          if (index !== -1) data.splice(index, 1);
          data.push(mass);
        });
      }

      masses = data;
    } catch(error) {
      console.log(error);

      if (localStorage['savedMasses'] != null && JSON.parse(localStorage['savedMasses']).length !== 0) {
        const savedMasses = JSON.parse(localStorage['savedMasses']).filter(mass =>
                  mass.constructionSiteId === this.props.selectedConstructionSite.get('id'));
        masses.concat(savedMasses);
      }

      if (localStorage['updatedMasses'] != null && JSON.parse(localStorage['updatedMasses']).length !== 0) {
        const updatedMasses = JSON.parse(localStorage['updatedMasses']).filter(
          mass => mass.constructionSiteId === this.props.selectedConstructionSite.get('id'));
        updatedMasses.forEach(mass => {
          const index = masses.findIndex(d => d.id === mass.id);
          if (index !== -1) masses.splice(index, 1);
          masses.push(mass);
        });
      }
    }

    this.props.addMasses(masses);
    this.setState({
      loadingMasses: false
    });
  }

  goChangeMass(massId) {
    this.setState({
      changingMass: massId,
    });
  }

  clearChangeMass() {
    this.setState({
      changingMass: null,
    });
  }

  changeMass(mass) {
    fetch('/masses/' + mass.get('id') + '/', 'PATCH', mass).then(async data => {
      this.props.showNotice('Massa muokattu', 'Ok');

      if (data.paths_id) {
        data.paths = await this.getPaths(data.paths_id);
      }

      this.props.changeMass(data);
      
      this.setState({
        changingMass: null
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Massan muokkaus epäonnistui', 'Error');
    });
  }

  changeLocalMass(mass) {
    let masses = JSON.parse(localStorage['savedMasses']);
    const index = masses.findIndex(mass => mass['id'] === this.state.changingMass.get('id'));
    masses[index] = mass;
    localStorage['savedMasses'] = JSON.stringify(masses);
    this.props.showNotice('Massa muokattu', 'Ok');
    this.props.changeMass(mass);
    this.setState({
      changingMass: null
    });
  }

  removeMass() {
    fetch('/masses/' + this.state.removingMass, 'DELETE').then(data => {
      this.props.showNotice('Massa poistettu', 'Ok')
      this.props.removeMassById(this.state.removingMass);
      this.setState({
        lastPath: this.props.masses.last() ? this.props.masses.last().get('paths').last() : null
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Massan poisto epäonnistui', 'Error');
    });
  }

  confirmRemoveMass(massId) {
    this.setState({
      removingMass: massId,
    });
    this.props.showConfirm('Poistetaanko massa?', this.removeMass);
  }

  removeLocalMass() {
    // Without timer does not work. Error: (Reducers may not dispatch actions.)
    timer(0).then(() => {
      let masses = JSON.parse(localStorage['savedMasses']);
      masses = masses.filter(mass => mass['id'] !== this.state.removingMass);
      localStorage['savedMasses'] = JSON.stringify(masses);
      this.props.showNotice('Massa poistettu', 'Ok')
      this.props.removeMassById(this.state.removingMass);
    });
  }

  confirmRemoveLocalMass(massId) {
    this.setState({ removingMass: massId });
    this.props.showConfirm('Poistetaanko massa?', this.removeLocalMass);
  }

  confirmRemoveLastPath(mass) {
    this.setState({ removingPathByMass: mass });
    this.props.showConfirm('Poistetaanko viimeisin levitys massasta?', this.removeLastPath);
  }

  removeLastPath() {
    fetch('/path/' + this.state.removingPathByMass.get('paths').last().get('id'), 'DELETE').then(data => {
      this.props.showNotice('Levitys poistettu', 'Ok')
      this.props.removeLastPath(this.state.removingPathByMass.get('id'));
      this.setState({ lastPath: this.props.masses.last().get('paths').last() });
    }).catch(error => {
      console.log(error);
      this.props.showMessage('Virhe', 'Levityksen poisto epäonnistui', 'Error');
    });
  }

  getDailyData(masses) {
    let dailyCount = 0;
    let dailyLength = 0;
    let dailyTruckMass = 0;
    let dailyMass = 0;
    let dailySmallAreas = 0;
    let dailyLevelingMass = 0;
    let dailyMassWithArea = 0;
    let dailyArea = 0;

    let wholeLength = 0;
    let wholeTruckMass = 0;
    let wholeMass = 0;
    let wholeSmallAreas = 0;
    let wholeLevelingMass = 0;
    let wholeMassWithArea = 0;
    let wholeArea = 0;

    let now = new Date();
    now.setHours(now.getHours());

    let past12HoursFromNow = new Date(now);
    past12HoursFromNow.setHours(past12HoursFromNow.getHours() - 12);

    let newDay = false;

    for (let i = masses.size - 1; i >= 0; i--) {
      const mass = masses.get(i);
      const date = new Date(mass.get('date'));

      if (!newDay && date >= past12HoursFromNow &&
          date <= now) {
        dailyCount++;
        dailyLength += mass.get('length');
        dailyTruckMass += mass.get('truck_mass');
        dailyMass += mass.get('mass');
        dailySmallAreas += mass.get('small_areas');
        dailyLevelingMass += mass.get('leveling_mass');
        
        if (mass.get('calculated_kg_per_square') != null) {
          dailyMassWithArea += mass.get('mass');
          dailyArea += mass.get('area');
        }
      }

      if (i !== 0) {
        let past6Hours = new Date(date);
        past6Hours.setHours(past6Hours.getHours() - 6);
        let beforeMassdate = new Date(masses.get(i - 1).get('date'));
        beforeMassdate.setHours(beforeMassdate.getHours());
        if (past6Hours >= beforeMassdate) {
          newDay = true;
        }
      }

      wholeLength += mass.get('length');
      wholeTruckMass += mass.get('truck_mass');
      wholeMass += mass.get('mass');
      wholeSmallAreas += mass.get('small_areas');
      wholeLevelingMass += mass.get('leveling_mass');
      
      if (mass.get('calculated_kg_per_square') != null) {
        wholeMassWithArea += mass.get('mass');
        wholeArea += mass.get('area');
      }
    }

    this.setState({
      dailyCount: dailyCount,
      dailyLength: dailyLength,
      dailyTruckMass: dailyTruckMass,
      dailyMass: dailyMass,
      dailySmallAreas: dailySmallAreas,
      dailyLevelingMass: dailyLevelingMass,
      dailyCurrentMassPerSquare: dailyMassWithArea * 1000 / dailyArea,
      wholeLength: wholeLength,
      wholeTruckMass: wholeTruckMass,
      wholeMass: wholeMass,
      wholeSmallAreas: wholeSmallAreas,
      wholeLevelingMass: wholeLevelingMass,
      wholeCurrentMassPerSquare: wholeMassWithArea * 1000 / wholeArea,
    });
  }

  getLastMassData(masses) {
    let lastMassPart = 0;
    let lastMassPole = 0;
    let lastWidth = null;
    let lastMassPerSquare = null;
    let lastThickness = null;
    let lastDirection = null;
    let lastroadway = null;
    let lastlane = null;
    let lastTemperature = null;
    let lastMillingDeepnessRight = null;
    let lastMillingDeepnessLeft = null;
    let lastREMTemperature = null;
    let lastLocationOnRoad = null;
    let lastPath = null;

    const lastMass = masses.last();

    if (lastMass != null) {
      if (lastMass.get('paths').size !== 0) {
        const path = lastMass.get('paths').last();

        if (path.get('end_part')) {
          lastMassPart = path.get('end_part');
          lastMassPole = path.get('end_distance');
        }
        else {
          lastMassPart = path.get('start_part')
          lastMassPole = path.get('start_distance');
        }

        lastPath = path;
        lastWidth = path.get('width');
        lastMassPerSquare = path.get('mass_per_square');
        lastDirection = path.get('direction');
        lastroadway = path.get('roadway');
        lastlane = path.get('lane');
      }

      lastThickness = lastMass.get('thickness');
      lastTemperature = lastMass.get('temperature');
      lastMillingDeepnessRight = lastMass.get('milling_deepness_right');
      lastMillingDeepnessLeft = lastMass.get('milling_deepness_left');
      lastREMTemperature = lastMass.get('REM_temperature');
      lastLocationOnRoad = lastMass.get('location_on_road');
    }

    this.setState({
      lastMassPart: lastMassPart,
      lastMassPole: lastMassPole,
      lastWidth: lastWidth,
      lastMassPerSquare: lastMassPerSquare,
      lastThickness: lastThickness,
      lastDirection: lastDirection,
      lastroadway: lastroadway,
      lastlane: lastlane,
      lastTemperature: lastTemperature,
      lastMillingDeepnessRight: lastMillingDeepnessRight,
      lastMillingDeepnessLeft: lastMillingDeepnessLeft,
      lastREMTemperature: lastREMTemperature,
      lastLocationOnRoad: lastLocationOnRoad,
      lastPath: lastPath
    });
  }

  resetTruck() {
    this.setState({
      truckRegisterNumber: '',
      load: null,
      truckMass: 0
    });
    this.props.selectLoad(null);
  }

  setView(view) {
    this.setState({
      view: view
    });
  }

  toggleAccuracyFixer(submit) {
    this.setState({
      accuracySubmit: submit,
      showAccuracyFixer: !this.state.showAccuracyFixer
    });
  }

  toggleAddSmallArea() {
    this.setState({
      showAddSmallArea: !this.state.showAddSmallArea,
      selectedMass: null
    });
  }

  addSmallArea() {
    const smallArea = this.state.addingSmallArea;

    if (smallArea == null || smallArea === '') {
      this.props.showNotice('Pinta-alaa ei ole annettu', 'Warning');
      return;
    }

    let mass = this.state.selectedMass;

    if (mass == null) {
      this.props.showNotice('Kuormaa ei ole valittu', 'Warning');
      return;
    }

    this.toggleAddSmallArea();
    mass = mass.set('small_areas', smallArea);
    this.changeMass(mass)
  }

  selectMass(mass) {
    this.setState({
      selectedMass: mass
    });
  }

  setLocationWatchAgain() {
    navigator.geolocation.clearWatch(this.watchID);
    this.watchID = navigator.geolocation.watchPosition(this.setLocation, this.locationError, 
      {enableHighAccuracy: true});
  }

  setCrosshairValue(value, {index}) {
    const mass = this.state.calculatedMasses[index];
    const date = new Date(mass.get('date'));
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    this.setState({
      crosshairValue: [{
         x: index,
         time: time,
         massPerSquare: Math.round(mass.get('calculated_kg_per_square') * 100) / 100,
         wholeMassPerSquare: Math.round(this.state.graphWholeData[index]['y'] * 100) / 100
      }]
    });
  }

  resetCrosshairValue() {
    this.setState({crosshairValue: []});
  }

  async getPaths(id) {
    const paths = await fetch('/paths/' + id);
    return paths;
  }

  async findMass(time, site) {
    let data = await fetch('/masses?site=' + site);
    const sensorTime = new Date(time);

    for (let index in data) {
      const mass = data[data.length - index - 1];

      if (new Date(mass.date) - sensorTime) {
        return mass.id;
      }
    }

    return null;
  }

  toggleSensors() {
    const value = !this.state.sensor;

    if (value) {
      this.sensorTimer = setInterval(this.updateSensorValues, 7500);
    }
    else {
      clearInterval(this.sensorTimer);
    }

    this.setState({ sensor: value });
    localStorage.sensor = value;
  }

  jsonToObject(csv) {
    let arr = csv.replace('\r', '').split('\n'); 
    let jsonObj = [];
    let headers = arr[0].split(',');


    for (let i = 1; i < arr.length; i++) {
      let data = arr[i].split(',');
      let obj = {};

      for (let j = 0; j < data.length; j++) {
        if (j === headers.length) {
          break;
        }

        obj[headers[j].trim()] = data[j].trim();
      }

      jsonObj.push(obj);
    }

    return jsonObj;
  }

  async getSensorDevices() {
    let csv;

    try {
      csv = await fetchSensorData(this.SENSOR_TIME_AGO);
    } catch(error) {
      this.setState({
        selectedSensorDevice: ''
      });
    }

    if (csv == null) return;

    const jsonObj = this.jsonToObject(csv);

    const devices = jsonObj.filter(field => field['device'] != null);

    let existSelectedDevice = false;

    let deviceNames = [];

    for (let index in devices) {
      const name = devices[index]['device'];
      deviceNames[index] = name;

      if (name === localStorage.selectedSensorDevice) {
        existSelectedDevice = true;
      }
    }

    this.setState({
      sensorDevices: deviceNames,
      selectedSensorDevice: existSelectedDevice ? localStorage.selectedSensorDevice : ''
    });
  }

  async getSensorValues() {
    if (this.state.selectedSensorDevice === '') {
      if (this.state.sensorDevices.length === 0) {
        this.getSensorDevices();
      }

      return;
    }

    let csv;

    try {
      csv = await fetchSensorData(this.SENSOR_TIME_AGO, this.state.selectedSensorDevice, false);
    } catch(error) {

    }

    if (csv == null) return null;

    const sensorData = this.jsonToObject(csv);

    const widthLeftValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                  && field['sensor_id'] === 'ULTRA1');
    const widthRightValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                    && field['sensor_id'] === 'ULTRA2');

    const deepnessLeftOriginalValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                              && field['sensor_id'] === 'ULTRA3');
    const deepnessRightOriginalValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                              && field['sensor_id'] === 'ULTRA4');
    const deepnessLeftAfterValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                          && field['sensor_id'] === 'ULTRA5');
    const deepnessRightAfterValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                            && field['sensor_id'] === 'ULTRA6');

    const temperatureLeftValues = sensorData.filter(field => field['master_ip'] === '192.168.19.3'
                                                   && field['sensor_id'] === 'TEMP1');
    const temperatureMiddleValues = sensorData.filter(field => field['master_ip'] === '192.168.19.3'
                                                     && field['sensor_id'] === 'TEMP2');
    const temperatureRightValues = sensorData.filter(field => field['master_ip'] === '192.168.19.3'
                                                    && field['sensor_id'] === 'TEMP3');
    const temperatureREMValues = sensorData.filter(field => field['master_ip'] === '192.168.19.3'
                                                  && field['sensor_id'] === 'TEMP4');

    return {
      widthLeftValues: widthLeftValues,
      widthRightValues: widthRightValues,
      deepnessLeftOriginalValues: deepnessLeftOriginalValues,
      deepnessRightOriginalValues: deepnessRightOriginalValues,
      deepnessLeftAfterValues: deepnessLeftAfterValues,
      deepnessRightAfterValues: deepnessRightAfterValues,
      temperatureLeftValues: temperatureLeftValues,
      temperatureMiddleValues: temperatureMiddleValues,
      temperatureRightValues: temperatureRightValues,
      temperatureREMValues: temperatureREMValues
    };
  }

  async updateSensorValues() {
    const sensorValues = await this.getSensorValues();

    if (sensorValues == null) {
      return;
    }

    let widthLeftValues = sensorValues.widthLeftValues;
    let widthRightValues = sensorValues.widthRightValues;
    let deepnessLeftOriginalValues = sensorValues.deepnessLeftOriginalValues;
    let deepnessRightOriginalValues = sensorValues.deepnessRightOriginalValues;
    let deepnessLeftAfterValues = sensorValues.deepnessLeftAfterValues;
    let deepnessRightAfterValues = sensorValues.deepnessRightAfterValues;
    let temperatureLeftValues = sensorValues.temperatureLeftValues;
    let temperatureMiddleValues = sensorValues.temperatureMiddleValues;
    let temperatureRightValues = sensorValues.temperatureRightValues;
    let temperatureREMValues = sensorValues.temperatureREMValues;

    let lastUpdatedtime;

    let leftSensorWidth = null;
    let rightSensorWidth = null;

    if (widthLeftValues.length !== 0) {
      leftSensorWidth = parseFloat(widthLeftValues[widthLeftValues.length - 1]['_value']);
      lastUpdatedtime = new Date(widthLeftValues[widthLeftValues.length - 1]['_time']);
    }

    if (widthRightValues.length !== 0) {
      rightSensorWidth = parseFloat(widthRightValues[widthRightValues.length - 1]['_value']);
      const time = new Date(widthRightValues[widthRightValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    const calculatedWidth = Math.round((leftSensorWidth / 1000 +
      this.state.lengthBetweenWidthSensors / 100
      + rightSensorWidth / 1000) * 100) / 100;

    let leftOriginalSensorDeepness = null;
    let rightOriginalSensorDeepness = null;
    let leftAfterSensorDeepness = null;
    let rightAfterSensorDeepness = null;
    let leftSensorDeepness = null;
    let rightSensorDeepness = null;

    if (deepnessLeftOriginalValues.length !== 0) {
      leftOriginalSensorDeepness = parseFloat(deepnessLeftOriginalValues[deepnessLeftOriginalValues.length - 1]['_value']);

      const time = new Date(deepnessLeftOriginalValues[deepnessLeftOriginalValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    if (deepnessRightOriginalValues.length !== 0) {
      rightOriginalSensorDeepness = parseFloat(deepnessRightOriginalValues[deepnessRightOriginalValues.length - 1]['_value']);

      const time = new Date(deepnessRightOriginalValues[deepnessRightOriginalValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    if (deepnessLeftAfterValues.length !== 0) {
      leftAfterSensorDeepness = parseFloat(deepnessLeftAfterValues[deepnessLeftAfterValues.length - 1]['_value']);

      const time = new Date(deepnessLeftAfterValues[deepnessLeftAfterValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    if (deepnessRightAfterValues.length !== 0) {
      rightAfterSensorDeepness = parseFloat(deepnessRightAfterValues[deepnessRightAfterValues.length - 1]['_value']);

      const time = new Date(deepnessRightAfterValues[deepnessRightAfterValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    if (leftOriginalSensorDeepness && leftAfterSensorDeepness) {
      if (this.state.reverseLeftDeepness === 0) {
        leftSensorDeepness = leftAfterSensorDeepness - leftOriginalSensorDeepness;
      }
      else {
        leftSensorDeepness = leftOriginalSensorDeepness - leftAfterSensorDeepness;
      }

      leftSensorDeepness -= this.state.deepnessSensorLeftCalibrateValue;
    }

    if (rightOriginalSensorDeepness && rightAfterSensorDeepness) {
      if (this.state.reverseLeftDeepness === 0) {
        rightSensorDeepness = rightAfterSensorDeepness - rightOriginalSensorDeepness;
      }
      else {
        rightSensorDeepness = rightOriginalSensorDeepness - rightAfterSensorDeepness;
      }

      rightSensorDeepness -= this.state.deepnessSensorRightCalibrateValue;
    }

    let leftSensorTemperature = null;
    let middleSensorTemperature = null;
    let rightSensorTemperature = null;
    let REMSensorTemperature = null;

    if (temperatureLeftValues.length !== 0) {
      leftSensorTemperature = parseFloat(temperatureLeftValues[temperatureLeftValues.length - 1]['_value']) / 10;

      const time = new Date(temperatureLeftValues[temperatureLeftValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    if (temperatureMiddleValues.length !== 0) {
      middleSensorTemperature = parseFloat(temperatureMiddleValues[temperatureMiddleValues.length - 1]['_value']) / 10;

      const time = new Date(temperatureMiddleValues[temperatureMiddleValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    if (temperatureRightValues.length !== 0) {
      rightSensorTemperature = parseFloat(temperatureRightValues[temperatureRightValues.length - 1]['_value']) / 10;

      const time = new Date(temperatureRightValues[temperatureRightValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    if (temperatureREMValues.length !== 0) {
      REMSensorTemperature = parseFloat(temperatureREMValues[temperatureREMValues.length - 1]['_value']) / 10;

      const time = new Date(temperatureREMValues[temperatureREMValues.length - 1]['_time']);

      if (lastUpdatedtime != null && time > lastUpdatedtime) {
        lastUpdatedtime = time;
      }
    }

    let positionCSV;

    try {
      positionCSV = await fetchSensorData(this.SENSOR_TIME_AGO, this.state.selectedSensorDevice, true);
    } catch(error) {

    }

    const positions = this.jsonToObject(positionCSV);

    const latitudes = positions.filter(field => field['_field'] === 'lat');
    const longitudes = positions.filter(field => field['_field'] === 'lon');

    let lastSensorLatitude = null;
    let lastSensorLongitude = null;

    if (latitudes.length !== 0) {
      lastSensorLatitude = parseFloat(latitudes[latitudes.length - 1]['_value']);
      lastSensorLongitude = parseFloat(longitudes[longitudes.length - 1]['_value']);
    }

    this.setState({
      widthSensorLeftValue: leftSensorWidth / 10,
      widthSensorRightValue: rightSensorWidth / 10,
      widthSensorValue: calculatedWidth,
      deepnessOriginalSensorLeftTrueValue: leftOriginalSensorDeepness,
      deepnessOriginalSensorRightTrueValue: rightOriginalSensorDeepness,
      deepnessAfterSensorLeftTrueValue: leftAfterSensorDeepness,
      deepnessAfterSensorRightTrueValue: rightAfterSensorDeepness,
      deepnessSensorLeftValue: leftSensorDeepness,
      deepnessSensorRightValue: rightSensorDeepness,
      temperatureSensorLeftValue: leftSensorTemperature,
      temperatureSensorMiddleValue: middleSensorTemperature,
      temperatureSensorRightValue: rightSensorTemperature,
      temperatureSensorREMValue: REMSensorTemperature,
      lastSensortime: lastUpdatedtime,
      lastSensorLatitude: lastSensorLatitude,
      lastSensorLongitude: lastSensorLongitude
    });
  }

  getModeAndLocation(array, latitudes, longitudes, locationIndex) {
    let values = [];
  
    for (let i in array) {
        values.push(parseFloat(array[i]['_value']))
    }
  
    const medianValue = mode(values);
  
    const medianIndex = values.findIndex(value => value === medianValue);

    if (latitudes.length === 0) {
        return {index: medianIndex, coordinates: {latitude: 0, longitude: 0}, locationIndex: 0}
    }
  
    const valueTime = new Date(array[medianIndex]['_time']);
  
    for (let i = locationIndex; i < latitudes.length; i++) {
        const latitude = latitudes[i];
        const latitudeTime = new Date(latitude['_time']);
  
        if (valueTime < latitudeTime) {
            break;
        }
  
        locationIndex = i;
    }

    const latitude = parseFloat(latitudes[locationIndex]['_value']);
    const longitude = parseFloat(longitudes[locationIndex]['_value']);
    const coordinates = {latitude: latitude, longitude: longitude};

    return {index: medianIndex, coordinates: coordinates, locationIndex: locationIndex}
}

  async getSensorValuesWithPositions() {
    const sensorValues = await this.getSensorValues();
    let widthLeftValues = sensorValues.widthLeftValues;
    let widthRightValues = sensorValues.widthRightValues;
    let deepnessLeftOriginalValues = sensorValues.deepnessLeftOriginalValues;
    let deepnessRightOriginalValues = sensorValues.deepnessRightOriginalValues;
    let deepnessLeftAfterValues = sensorValues.deepnessLeftAfterValues;
    let deepnessRightAfterValues = sensorValues.deepnessRightAfterValues;
    let temperatureLeftValues = sensorValues.temperatureLeftValues;
    let temperatureMiddleValues = sensorValues.temperatureMiddleValues;
    let temperatureRightValues = sensorValues.temperatureRightValues;
    let temperatureREMValues = sensorValues.temperatureREMValues;

    const lastPathTime = new Date(this.state.lastPath.get('date'));
    const endTime = new Date();
    
    widthLeftValues = widthLeftValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    widthRightValues = widthRightValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    deepnessLeftOriginalValues = deepnessLeftOriginalValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    deepnessRightOriginalValues = deepnessRightOriginalValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    deepnessLeftAfterValues = deepnessLeftAfterValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    deepnessRightAfterValues = deepnessRightAfterValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    temperatureLeftValues = temperatureLeftValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    temperatureMiddleValues = temperatureMiddleValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    temperatureRightValues = temperatureRightValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    temperatureREMValues = temperatureREMValues.filter(field => {
      const time = new Date(field['_time']);
      return (time >= lastPathTime  && time <= endTime);
    });

    const maxLength = Math.max(widthLeftValues.length, widthRightValues.length,
      deepnessLeftOriginalValues.length, deepnessRightOriginalValues.length, deepnessLeftAfterValues.length,
      deepnessRightAfterValues.length, temperatureLeftValues.length, temperatureMiddleValues.length,
      temperatureRightValues.length, temperatureREMValues.length);

    if (maxLength === 0) return;

    let positionCSV;

    try {
      positionCSV = await fetchSensorData(this.SENSOR_TIME_AGO, this.state.selectedSensorDevice, true);
    } catch(error) {

    }

    const positions = this.jsonToObject(positionCSV);

    let latitudes = positions.filter(field => field['_field'] === 'lat');
    let longitudes = positions.filter(field => field['_field'] === 'lon');

    let mostValues;

    if (maxLength === widthLeftValues.length) {
      mostValues = widthLeftValues;
    }
    else if (maxLength === widthRightValues.length) {
      mostValues = widthRightValues;
    }
    else if (maxLength === deepnessLeftOriginalValues.length) {
      mostValues = deepnessLeftOriginalValues;
    }
    else if (maxLength === deepnessRightOriginalValues.length) {
      mostValues = deepnessRightOriginalValues;
    }
    else if (maxLength === deepnessLeftAfterValues.length) {
      mostValues = deepnessLeftAfterValues;
    }
    else if (maxLength === deepnessRightAfterValues.length) {
      mostValues = deepnessRightAfterValues;
    }
    else if (maxLength === temperatureLeftValues.length) {
      mostValues = temperatureLeftValues;
    }
    else if (maxLength === temperatureMiddleValues.length) {
      mostValues = temperatureMiddleValues;
    }
    else if (maxLength === temperatureRightValues.length) {
      mostValues = temperatureRightValues;
    }
    else if (maxLength === temperatureREMValues.length) {
      mostValues = temperatureREMValues;
    }

    const INTERVAL = 60;

    let widthLeftLocationIndex = 0;
    let widthRightLocationIndex = 0;
    let deepnessLeftOriginalLocationIndex = 0;
    let deepnessRightOriginalLocationIndex = 0;
    let deepnessLeftAfterLocationIndex = 0;
    let deepnessRightAfterLocationIndex = 0;
    let temperatureLeftLocationIndex = 0;
    let temperatureMiddleLocationIndex = 0;
    let temperatureRightLocationIndex = 0;
    let temperatureREMLocationIndex = 0;

    for (let index = 0; index < mostValues.length; index += INTERVAL) {
      const end = index + INTERVAL - 1;

      if (end < widthLeftValues.length) {
          const array = widthLeftValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, widthLeftLocationIndex);
          widthLeftLocationIndex = median.locationIndex;
          widthLeftValues[median.index + index].location = median.coordinates;
      }
      
      if (end < widthRightValues.length) {
          const array = widthRightValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, widthRightLocationIndex);
          widthRightLocationIndex = median.locationIndex;
          widthRightValues[median.index + index].location = median.coordinates;
      }

      if (end < deepnessLeftOriginalValues.length) {
          const array = deepnessLeftOriginalValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, deepnessLeftOriginalLocationIndex);
          deepnessLeftOriginalLocationIndex = median.locationIndex;
          deepnessLeftOriginalValues[median.index + index].location = median.coordinates;
      }

      if (end < deepnessRightOriginalValues.length) {
          const array = deepnessRightOriginalValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, deepnessRightOriginalLocationIndex);
          deepnessRightOriginalLocationIndex = median.locationIndex;
          deepnessRightOriginalValues[median.index + index].location = median.coordinates;
      }

      if (end < deepnessLeftAfterValues.length) {
          const array = deepnessLeftAfterValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, deepnessLeftAfterLocationIndex);
          deepnessLeftAfterLocationIndex = median.locationIndex;
          deepnessLeftAfterValues[median.index + index].location = median.coordinates;
      }

      if (end < deepnessRightAfterValues.length) {
          const array = deepnessRightAfterValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, deepnessRightAfterLocationIndex);
          deepnessRightAfterLocationIndex = median.locationIndex;
          deepnessRightAfterValues[median.index + index].location = median.coordinates;
      }

      if (end < temperatureLeftValues.length) {
          const array = temperatureLeftValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, temperatureLeftLocationIndex);
          temperatureLeftLocationIndex = median.locationIndex;
          temperatureLeftValues[median.index + index].location = median.coordinates;
      }

      if (end < temperatureMiddleValues.length) {
          const array = temperatureMiddleValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, temperatureMiddleLocationIndex);
          temperatureMiddleLocationIndex = median.locationIndex;
          temperatureMiddleValues[median.index + index].location = median.coordinates;
      }

      if (end < temperatureRightValues.length) {
          const array = temperatureRightValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, temperatureRightLocationIndex);
          temperatureRightLocationIndex = median.locationIndex;
          temperatureRightValues[median.index + index].location = median.coordinates;
          
      }

      if (end < temperatureREMValues.length) {
          const array = temperatureREMValues.slice(index, end);
          const median = this.getModeAndLocation(array, latitudes, longitudes, temperatureREMLocationIndex);
          temperatureREMLocationIndex = median.locationIndex;
          temperatureREMValues[median.index + index].location = median.coordinates;
      }
    }

    for (let index = 0; index < mostValues.length; index += INTERVAL) {
      let indexMinus = false;

      if (widthLeftValues.length > index && widthLeftValues[index].location == null) {
          widthLeftValues.splice(index, 1);
          indexMinus = true;
      }
  
      if (widthRightValues.length > index && widthRightValues[index].location == null) {
          widthRightValues.splice(index, 1);
          indexMinus = true;
      }

      if (deepnessLeftOriginalValues.length > index && deepnessLeftOriginalValues[index].location == null) {
          deepnessLeftOriginalValues.splice(index, 1);
          indexMinus = true;
      }

      if (deepnessRightOriginalValues.length > index && deepnessRightOriginalValues[index].location == null) {
          deepnessRightOriginalValues.splice(index, 1);
          indexMinus = true;
      }

      if (deepnessLeftAfterValues.length > index && deepnessLeftAfterValues[index].location == null) {
          deepnessLeftAfterValues.splice(index, 1);
          indexMinus = true;
      }

      if (deepnessRightAfterValues.length > index && deepnessRightAfterValues[index].location == null) {
          deepnessRightAfterValues.splice(index, 1);
          indexMinus = true;
      }

      if (temperatureLeftValues.length > index && temperatureLeftValues[index].location == null) {
          temperatureLeftValues.splice(index, 1);
          indexMinus = true;
      }

      if (temperatureMiddleValues.length > index && temperatureMiddleValues[index].location == null) {
          temperatureMiddleValues.splice(index, 1);
          indexMinus = true;
      }

      if (temperatureRightValues.length > index && temperatureRightValues[index].location == null) {
          temperatureRightValues.splice(index, 1);
          indexMinus = true;
      }

      if (temperatureREMValues.length > index && temperatureREMValues[index].location == null) {
          temperatureREMValues.splice(index, 1);
          indexMinus = true;
      }

      if (indexMinus) {
          index--;
      }
    }

    return {
      widthLeftValues: widthLeftValues,
      widthRightValues: widthRightValues,
      deepnessLeftOriginalValues: deepnessLeftOriginalValues,
      deepnessRightOriginalValues: deepnessRightOriginalValues,
      deepnessLeftAfterValues: deepnessLeftAfterValues,
      deepnessRightAfterValues: deepnessRightAfterValues,
      temperatureLeftValues: temperatureLeftValues,
      temperatureMiddleValues: temperatureMiddleValues,
      temperatureRightValues: temperatureRightValues,
      temperatureREMValues: temperatureREMValues
    };
  }

  newestSensorValue(value, value2) {
    const date = new Date(value['_time']);
    const date2 = new Date(value2['_time']);
  
    if (date < date2) {
      return value2;
    }
  
    return value;
  }

  async sendSensorData() {
    const sensorValues = await this.getSensorValuesWithPositions();
    let widthLeftValues = sensorValues.widthLeftValues;
    let widthRightValues = sensorValues.widthRightValues;
    let deepnessLeftOriginalValues = sensorValues.deepnessLeftOriginalValues;
    let deepnessRightOriginalValues = sensorValues.deepnessRightOriginalValues;
    let deepnessLeftAfterValues = sensorValues.deepnessLeftAfterValues;
    let deepnessRightAfterValues = sensorValues.deepnessRightAfterValues;
    let temperatureLeftValues = sensorValues.temperatureLeftValues;
    let temperatureMiddleValues = sensorValues.temperatureMiddleValues;
    let temperatureRightValues = sensorValues.temperatureRightValues;
    let temperatureREMValues = sensorValues.temperatureREMValues;

    if (this.state.widthSensor) {
      this.sendWidthSensorValues(widthLeftValues, widthRightValues);
    }

    if (this.state.deepnessSensor) {
      this.sendDeepnessSensorValues(deepnessLeftOriginalValues,
        deepnessRightOriginalValues, deepnessLeftAfterValues, deepnessRightAfterValues);
    }

    if (this.state.temperatureSensor) {
      this.sendTemperatureSensorValues(temperatureLeftValues, temperatureMiddleValues,
        temperatureRightValues, temperatureREMValues);
    }
  }

  toggleWidthSensor() {
    const value = !this.state.widthSensor;

    if (!value) {
      this.setState({ 
        widthSensorValue: null,
        widthSensorLeftValue: null,
        widthSensorRightValue: null
       });
    }

    this.setState({ widthSensor: value });
    localStorage.widthSensor = value;
  }
  

  async sendWidthSensorValues(leftSensorValues, rightSensorValues) {
    const lastMass = this.props.masses.last();
    const widthUrl = '/width?mass=' + lastMass.get('id');

    const existingWidths = await fetch(widthUrl);

    let currentNoticeId = parseInt(localStorage.currentWidthNotice, 10);
    let lastWidthOk = false;

    for (let index in leftSensorValues) {
      let leftSensorValue = parseFloat(leftSensorValues[index]['_value']);
      let rightSensorValue = parseFloat(rightSensorValues[index]['_value']);

      const calculatedValue = Math.round((leftSensorValue / 1000 +
                                this.state.lengthBetweenWidthSensors / 100
                                + rightSensorValue / 1000) * 100) / 100;

      const newerValue = this.newestSensorValue(leftSensorValues[index], rightSensorValues[index]);
      let date = new Date(newerValue['_time']);
      const timezoneOffset = date.getTimezoneOffset() / 60;
      date.setHours(date.getHours() - timezoneOffset);
      const dateString = date.toISOString().replace('Z', '')

      const sameExist = existingWidths.find(width => width.time === dateString.substring(0, 19));

      if (sameExist != null) {
        continue;
      }

      let width = calculatedValue;
      const latitude = newerValue['location'].latitude;
      const longitude = newerValue['location'].longitude;

      const converted = toETRSTM35FIN(latitude, longitude);
      const roadData = await getRoadData(converted.y, converted.x);
      const roadNumber = roadData.road;
      const roadPart = roadData.part;
      const roadDistance = roadData.distance;

      if (this.state.setTargetWidth) {
        const widthSensorAlertLimit = 0.2;

        if (this.state.setTargetWidth + widthSensorAlertLimit <= width ||
            this.state.setTargetWidth - widthSensorAlertLimit >= width) {
          if (lastWidthOk) {
            const notice = {
              start_latitude: latitude,
              start_longitude: longitude,
              start_road_part: roadPart || null,
              start_road_distance: roadDistance || null,
            };
            currentNoticeId = await this.createNotice(notice);
            this.props.showAlertConfirm('Leveys heitto. Tavoite leveys: '+ this.state.setTargetWidth +
                                        ' Nykyinen leveys: ' + width, this.confirmNoticeText.bind(this, currentNoticeId));
            lastWidthOk = false;
          }
        }
        else {
          if (currentNoticeId != null) {
            const notice = {
              end_latitude: latitude,
              end_longitude: longitude,
              end_road_part: roadPart || null,
              end_road_distance: roadDistance || null,
            };

            this.updateNotice(currentNoticeId, notice);
            currentNoticeId = null;
          }

          lastWidthOk = true;
        }
      }

      let widthData = {
        mass_id: lastMass.get('id'),
        time: dateString,
        latitude: latitude,
        longitude: longitude,
        road_number: roadNumber,
        road_part: roadPart,
        road_distance: roadDistance,
        width: width,
        unknownMass: lastMass.get('not_saved'),
        constructionSite: this.props.selectedConstructionSite.get('id')
      };

      if (currentNoticeId != null) {
        widthData.notice_id = currentNoticeId;
      }

      await this.sendSavedWidths();

      try {
        if (widthData.unknownMass) {
          throw Error('Unknown mass');
        }
        await fetch('/width', 'POST', widthData);
      } catch(error) {
        if (error.toString() !== 'Error: 500') {
          if (localStorage['savedWidths'] == null) {
            localStorage['savedWidths'] = JSON.stringify([]);
          }
          let widths = JSON.parse(localStorage['savedWidths']);
          widths.push(widthData);
          localStorage['savedWidths'] = JSON.stringify(widths);
        }
      }
    }

    localStorage.currentWidthNotice = currentNoticeId;
  }

  async sendSavedWidths() {
    if (localStorage['savedWidths'] != null) {
      let savedWidths = JSON.parse(localStorage['savedWidths']);
      savedWidths = savedWidths.filter((v,i) => savedWidths
                      .findIndex(widths => widths.time === v.time) === i); // Removes duplicates

      let newWidths = savedWidths.slice();

      for (let index in savedWidths) {
        let savedWidth = savedWidths[index];

        try {
          if (savedWidth.unknownMass) {
            const massId = await this.findMass(savedWidth.time, savedWidth.constructionSite);
            savedWidth.mass_id = massId;
          }
          await fetch('/width', 'POST', savedWidth);
          newWidths.splice(newWidths.findIndex(width => width['time'] === savedWidth['time']), 1);
        } catch(error) {
          if (error.toString() === 'Error: 500') {
            newWidths.splice(newWidths.findIndex(width => width['time'] === savedWidth['time']), 1);
          }
        }
      }

      if (newWidths.length === 0) {
        localStorage.removeItem('savedWidths');
      }
      else {
        localStorage['savedWidths'] = JSON.stringify(newWidths);
      }
    }
  }

  toggleDeepnessSensor() {
    const value = !this.state.deepnessSensor;
    this.setState({ deepnessSensor: value });
    localStorage.deepnessSensor = value;
  }

  async sendDeepnessSensorValues(leftOriginalSensorValues, rightOriginalSensorValues,
                                 leftAfterSensorValues, rightAfterSensorValues) {
    const lastMass = this.props.masses.last();
    const deepnessUrl = '/deepness?mass=' + lastMass.get('id');

    const existingDeepnesses = await fetch(deepnessUrl);

    const maxLength = Math.max(leftOriginalSensorValues.length, rightOriginalSensorValues.length);

    const deepnessSensorAlertLimit = 30;

    let leftOk = true;
    let rightOk = true;
    let lastDeepnessOk = false;
    let currentNoticeId = parseInt(localStorage.currentDeepnessNotice, 10);

    for (let index = 0; index < maxLength; index++) {
      let leftOriginalValue;
      let rightOriginalValue;
      let leftAfterValue;
      let rightAfterValue;

      let leftDeepness;
      let rightDeepness;
      let leftLatitude;
      let leftLongitude;
      let rightLatitude;
      let rightLongitude;
      let leftTime;
      let rightTime;
      let leftRoadNumber;
      let leftRoadPart;
      let leftRoadDistance;
      let rightRoadNumber;
      let rightRoadPart;
      let rightRoadDistance;

      if (index < leftOriginalSensorValues.length) {
        leftOriginalValue = parseFloat(leftOriginalSensorValues[index]['_value']);
      }

      if (index < rightOriginalSensorValues.length) {
        rightOriginalValue = parseFloat(rightOriginalSensorValues[index]['_value']);
      }

      if (index < leftAfterSensorValues.length) {
        leftAfterValue = parseFloat(leftAfterSensorValues[index]['_value']);
      }

      if (index < rightAfterSensorValues.length) {
        rightAfterValue = parseFloat(rightAfterSensorValues[index]['_value']);
      }

      if (leftOriginalValue && leftAfterValue) {
        let value;

        if (this.state.reverseLeftDeepness === 0) {
          value = leftAfterValue - leftOriginalValue - this.state.deepnessSensorLeftCalibrateValue;
        }
        else {
          value = leftOriginalValue - leftAfterValue - this.state.deepnessSensorLeftCalibrateValue;
        }

        if (value > 0) {
          const newestValue = this.newestSensorValue(leftOriginalSensorValues[index], leftAfterSensorValues[index]);
          let date = new Date(newestValue['_time']);
          const timezoneOffset = date.getTimezoneOffset() / 60;
          date.setHours(date.getHours() - timezoneOffset);
          leftTime = date.toISOString().replace('Z', '');

          const sameExist = existingDeepnesses.find(deepness => deepness.time === leftTime.substring(0, 19)
                                                    && deepness.sensor_id === 0);

          if (sameExist == null) {
            leftDeepness = value;
            leftLatitude = newestValue['location'].latitude;
            leftLongitude = newestValue['location'].longitude;
            const converted = toETRSTM35FIN(leftLatitude, leftLongitude);
            const roadData = await getRoadData(converted.y, converted.x);
            leftRoadNumber = roadData.road;
            leftRoadPart = roadData.part;
            leftRoadDistance = roadData.distance;
          }
        }
      }

      if (rightOriginalValue && rightAfterValue) {
        let value;

        if (this.state.reverseRightDeepness === 0) {
          value = rightAfterValue - rightOriginalValue - this.state.deepnessSensorRightCalibrateValue;
        }
        else {
          value = rightOriginalValue - rightAfterValue - this.state.deepnessSensorRightCalibrateValue;
        }

        if (value > 0) {
          const newestValue = this.newestSensorValue(rightOriginalSensorValues[index], rightAfterSensorValues[index]);
          let date = new Date(newestValue['_time']);
          const timezoneOffset = date.getTimezoneOffset() / 60;
          date.setHours(date.getHours() - timezoneOffset);
          rightTime = date.toISOString().replace('Z', '')

          const sameExist = existingDeepnesses.find(deepness => deepness.time === rightTime.substring(0, 19)
                                                                && deepness.sensor_id === 1);

          if (sameExist == null) {
            rightDeepness = value;
            rightLatitude = newestValue['location'].latitude;
            rightLongitude = newestValue['location'].longitude;
            const converted = toETRSTM35FIN(rightLatitude, rightLongitude);
            const roadData = await getRoadData(converted.y, converted.x);
            rightRoadNumber = roadData.road;
            rightRoadPart = roadData.part;
            rightRoadDistance = roadData.distance;
          }
        }
      }

      if (leftDeepness == null && rightDeepness == null) {
        continue
      }

      let deepness = {
        mass_id: lastMass.get('id'),
        unknownMass: lastMass.get('not_saved'),
        constructionSite: this.props.selectedConstructionSite.get('id')
      };

      let deepnesses = [];

      if (leftDeepness) {
        if (deepnessSensorAlertLimit >= leftDeepness) {
          leftOk = false;
        }

        deepness.deepness = leftDeepness;
        deepness.sensor_id = 0;
        deepness.latitude = leftLatitude;
        deepness.longitude = leftLongitude;
        deepness.road_number = leftRoadNumber;
        deepness.road_part = leftRoadPart;
        deepness.road_distance = leftRoadDistance;
        deepness.time = leftTime;

        deepnesses.push(Object.assign({}, deepness));
      }

      if (rightDeepness) {
        if (deepnessSensorAlertLimit >= rightDeepness) {
          rightOk = false;
        }

        deepness.deepness = rightDeepness;
        deepness.sensor_id = 1;
        deepness.latitude = rightLatitude;
        deepness.longitude = rightLongitude;
        deepness.road_number = rightRoadNumber;
        deepness.road_part = rightRoadPart;
        deepness.road_distance = rightRoadDistance;
        deepness.time = rightTime;

        deepnesses.push(Object.assign({}, deepness));
      }

      if ((!leftOk || !rightOk)) {
        if (lastDeepnessOk) {
          const notice = {
            start_latitude: rightLatitude || leftLatitude,
            start_longitude: rightLongitude || leftLongitude,
            start_road_part: rightRoadPart || leftRoadPart,
            start_road_distance: rightRoadDistance || leftRoadDistance
          };
          currentNoticeId = await this.createNotice(notice);
          this.props.showAlertConfirm('Syvyys heitto. Vasen syvyys: ' + ((leftDeepness + ' mm') || '-') +
          ' Oikea syvyys: ' + ((rightDeepness + ' mm') || '-'), this.confirmNoticeText.bind(this, currentNoticeId));
          lastDeepnessOk = false;
        }
      }
      else {
        if (currentNoticeId != null) {
          const notice = {
          end_latitude: rightLatitude || leftLatitude,
          end_longitude: rightLongitude || leftLongitude,
          end_road_part: rightRoadPart || leftRoadPart,
          end_road_distance: rightRoadDistance || leftRoadDistance
          };
        
          this.updateNotice(currentNoticeId, notice);
          currentNoticeId = null;
        }
        
        lastDeepnessOk = true;
      }

      await this.sendSavedDeepnesses();

      for (let index in deepnesses) {
        let deepness = deepnesses[index];

        if (currentNoticeId != null) {
          deepness.notice_id = currentNoticeId;
        }

        try {
          if (deepness.unknownMass) {
            throw Error('Unknown mass');
          }
          await fetch('/deepness', 'POST', deepness);
        } catch(error) {
          if (error.toString() !== 'Error: 500') {
            if (localStorage['savedDeepnesses'] == null) {
              localStorage['savedDeepnesses'] = JSON.stringify([]);
            }
            let deepnesses = JSON.parse(localStorage['savedDeepnesses']);
            deepnesses.push(deepness);
            localStorage['savedDeepnesses'] = JSON.stringify(deepnesses);
          }
        }
      }
    }

    localStorage.currentDeepnessNotice = currentNoticeId;
  }

  async setCalibrationValues() {
    if (this.state.selectedSensorDevice === '') {
      return;
    }

    localStorage['deepnessCalibrationDate-' + this.state.selectedSensorDevice] = this.state.deepnessCalibrationDate
    localStorage['deepnessCalibrationTime-' + this.state.selectedSensorDevice] = this.state.deepnessCalibrationTime;
    localStorage['reverseLeftDeepness-' + this.state.selectedSensorDevice] = this.state.reverseLeftDeepness;
    localStorage['reverseRightDeepness-' + this.state.selectedSensorDevice] = this.state.reverseRightDeepness

    const calibrationTime = this.state.deepnessCalibrationDate + 'T' + 
                            this.state.deepnessCalibrationTime;
    let time = new Date(calibrationTime);

    if (isNaN(time)) {
      return;
    }

    const OFF_SET_MIN = 1; 

    time.setMinutes(time.getMinutes() - OFF_SET_MIN);
    const startTime = time.toISOString()

    time.setMinutes(time.getMinutes() + OFF_SET_MIN * 2);
    const endTime = time.toISOString()

    let csv;

    try {
      csv = await fetchSensorData(startTime, this.state.selectedSensorDevice, false, endTime);
    } catch(error) {
      this.props.showMessage('Virhe', 'Sensoridataa ei saatu palvelimelta', 'Error');
      return;
    }

    if (csv == null) return null;

    const sensorData = this.jsonToObject(csv);

    const deepnessLeftOriginalValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                              && field['sensor_id'] === 'ULTRA3');
    const deepnessRightOriginalValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                              && field['sensor_id'] === 'ULTRA4');
    const deepnessLeftAfterValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                          && field['sensor_id'] === 'ULTRA5');
    const deepnessRightAfterValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
                                                            && field['sensor_id'] === 'ULTRA6');

    let leftOriginal = 0;
    let leftAfter = 0;
    let leftOriginalValue = [];
    let leftAfterValue = [];

    const maxLengthLeft = Math.max(deepnessLeftOriginalValues.length, deepnessLeftAfterValues.length);

    for (let index = 0; index < maxLengthLeft; index++) {
      if (deepnessLeftOriginalValues.length > index) {
        leftOriginalValue.push(parseFloat(deepnessLeftOriginalValues[index]['_value']));
      }

      if (deepnessLeftAfterValues.length > index) {
        leftAfterValue.push(parseFloat(deepnessLeftAfterValues[index]['_value']));
      }
    }

    if (leftOriginalValue.length !== 0) {
      leftOriginal = mode(leftOriginalValue);
    }

    if (leftAfterValue.length !== 0) {
      leftAfter = mode(leftAfterValue);
    }

    let rightOriginal = 0;
    let rightAfter = 0;
    let rightOriginalValue = [];
    let rightAfterValue = [];

    const maxLengthRight = Math.max(deepnessRightOriginalValues.length, deepnessRightAfterValues.length);

    for (let index = 0; index < maxLengthRight; index++) {
      if (deepnessRightOriginalValues.length > index) {
        rightOriginalValue.push(parseFloat(deepnessRightOriginalValues[index]['_value']));
      }

      if (deepnessRightAfterValues.length > index) {
        rightAfterValue.push(parseFloat(deepnessRightAfterValues[index]['_value']));
      }
    }

    if (rightOriginalValue.length !== 0) {
      rightOriginal = mode(rightOriginalValue);
    }

    if (rightAfterValue.length !== 0) {
      rightAfter = mode(rightAfterValue);
    }

    this.setState({
      deepnessLeftOriginalCalibration: leftOriginal,
      deepnessLeftAfterCalibration: leftAfter,
      deepnessRightOriginalCalibration: rightOriginal,
      deepnessRightAfterCalibration: rightAfter
    });
  }

  async calibrateDeepnessSensors() {
    const leftOriginal = this.state.deepnessLeftOriginalCalibration;
    const leftAfter = this.state.deepnessLeftAfterCalibration;
    const rightOriginal = this.state.deepnessRightOriginalCalibration;
    const rightAfter = this.state.deepnessRightAfterCalibration;
    let leftDeepnessSensorDifference;
    let rightDeepnessSensorDifference;

    if (this.state.reverseLeftDeepness === 0) {
      leftDeepnessSensorDifference = leftAfter - leftOriginal;
    }
    else {
      leftDeepnessSensorDifference = leftOriginal - leftAfter;
    }

    if (this.state.reverseRightDeepness === 0) {
      rightDeepnessSensorDifference = rightAfter - rightOriginal;
    }
    else {
      rightDeepnessSensorDifference = rightOriginal - rightAfter;
    }

    this.setState({
      deepnessSensorLeftCalibrateValue: leftDeepnessSensorDifference,
      deepnessSensorRightCalibrateValue: rightDeepnessSensorDifference,
      deepnessCalibrationDone: true
    });
  }

  async sendSavedDeepnesses() {
    if (localStorage['savedDeepnesses'] != null) {
      let savedDeepnesses = JSON.parse(localStorage['savedDeepnesses']);
      savedDeepnesses = savedDeepnesses.filter((v,i) => savedDeepnesses
                          .findIndex(deepness => deepness.time === v.time) === i);

      let newDeepnesses = savedDeepnesses.slice();

      for (let index in savedDeepnesses) {
        let savedDeepness = savedDeepnesses[index];

        try {
          if (savedDeepness.unknownMass) {
            const massId = await this.findMass(savedDeepness.time, savedDeepness.constructionSite);
            savedDeepness.mass_id = massId;
          }
          await fetch('/deepness', 'POST', savedDeepness);
          newDeepnesses.splice(newDeepnesses.findIndex(deepness => deepness['time'] === savedDeepness['time']), 1);
        } catch(error) {
          if (error.toString() === 'Error: 500') {
            newDeepnesses.splice(newDeepnesses.findIndex(deepness => deepness['time'] === savedDeepness['time']), 1);
          }
        }
      }
      if (newDeepnesses.length === 0) {
        localStorage.removeItem('savedDeepnesses');
      }
      else {
        localStorage['savedDeepnesses'] = JSON.stringify(newDeepnesses);
      }
    }
  }

  toggleTemperatureSensor() {
    const value = !this.state.temperatureSensor;

    if (!value) {
      this.setState({ 
        temperatureSensorLeftValue: null,

        temperatureSensorMiddleValue: null,
        temperatureSensorRightValue: null,
        temperatureREMSensorValue: null
       });
    }

    this.setState({ temperatureSensor: value });
    localStorage.temperatureSensor = value;
  }

  async sendTemperatureSensorValues(leftSensorValues, middleSensorValues,
                                    rightSensorValues, REMSensorValues) {
    const temperatureUrl = '/temperature?site=' + this.props.selectedConstructionSite.get('id');

    const existingTemperatures = await fetch(temperatureUrl);

    const massType = this.props.selectedConstructionSite.get('mass_type').get('name')
                        .slice(0, -2);

    let minTemperature;
    let maxTemperature;
    let minREMTemperature = 70;
    let maxREMTemperature = 200;

    if (massType === 'AB') {
      minTemperature = 110;
      maxTemperature = 200;
    }
    else if (massType === 'SMA') {
      minTemperature = 130;
      maxTemperature = 220;
    }

    const maxLength = Math.max(leftSensorValues.length, middleSensorValues.length,
                               rightSensorValues.length, REMSensorValues.length);

    let leftOk = true;
    let midOk = true;
    let rightOk = true;
    let lastTemperatureOk = false;
    let lastREMTemperatureOk = false;
    let currentNoticeId = parseInt(localStorage.currentTemperatureNotice, 10);
    let currentREMNoticeId = parseInt(localStorage.currentREMTemperatureNotice, 10);

    for (let index = 0; index < maxLength; index++) {
      let leftValue;
      let middleValue;
      let rightValue;
      let REMValue;
      let leftTime;
      let middleTime;
      let rightTime;
      let REMTime;

      if (index < leftSensorValues.length) {
        let date = new Date(leftSensorValues[index]['_time']);
        const timezoneOffset = date.getTimezoneOffset() / 60;
        date.setHours(date.getHours() - timezoneOffset);
        leftTime = date.toISOString().replace('Z', '')

        const sameExist = existingTemperatures.find(temperature => temperature.time === leftTime.substring(0, 19)
                                                              && temperature.sensor_id === 0);

        if (sameExist == null) {
          const value = parseFloat(leftSensorValues[index]['_value']) / 10;
          leftValue = value;
        }
      }

      if (index < middleSensorValues.length) {
        let date = new Date(middleSensorValues[index]['_time']);
        const timezoneOffset = date.getTimezoneOffset() / 60;
        date.setHours(date.getHours() - timezoneOffset);
        middleTime = date.toISOString().replace('Z', '');

        const sameExist = existingTemperatures.find(temperature => temperature.time === middleTime.substring(0, 19)
                                                              && temperature.sensor_id === 1);

        if (sameExist == null) {
          const value = parseFloat(middleSensorValues[index]['_value']) / 10;
          middleValue = value;
        }
      }

      if (index < rightSensorValues.length) {
        let date = new Date(rightSensorValues[index]['_time']);
        const timezoneOffset = date.getTimezoneOffset() / 60;
        date.setHours(date.getHours() - timezoneOffset);
        rightTime = date.toISOString().replace('Z', '');

        const sameExist = existingTemperatures.find(temperature => temperature.time === rightTime.substring(0, 19)
                                                              && temperature.sensor_id === 2);

        if (sameExist == null) {
          const value = parseFloat(rightSensorValues[index]['_value']) / 10;
          rightValue = value;
        }
      }

      if (index < REMSensorValues.length) {
        let date = new Date(REMSensorValues[index]['_time']);
        const timezoneOffset = date.getTimezoneOffset() / 60;
        date.setHours(date.getHours() - timezoneOffset);
        REMTime = date.toISOString().replace('Z', '');

        const sameExist = existingTemperatures.find(temperature => temperature.time === REMTime.substring(0, 19)
                                                              && temperature.sensor_id === 3);

        if (sameExist == null) {
          const value = parseFloat(REMSensorValues[index]['_value']) / 10;
          REMValue = value;
        }
      }

      if (!leftValue &&
          !middleValue &&
          !rightValue &&
          !REMValue) {
        continue;
      }


      let temperature = {
        construction_site_id: this.props.selectedConstructionSite.get('id')
      };

      let temperatures = [];

      if (leftValue) {
        if (minTemperature >= leftValue ||
            maxTemperature <= leftValue) {
          leftOk = false;
        }

        temperature.temperature = leftValue;
        temperature.sensor_id = 0;
        temperature.time = leftTime;
        temperature.latitude = leftSensorValues[index]['location'].latitude;
        temperature.longitude = leftSensorValues[index]['location'].longitude;
        const converted = toETRSTM35FIN(temperature.latitude, temperature.longitude);
        const roadData = await getRoadData(converted.y, converted.x);
        temperature.roadNumber = roadData.road || null;
        temperature.roadPart = roadData.part || null;
        temperature.roadDistance = roadData.distance || null;

        temperatures.push(Object.assign({}, temperature));
      }

      if (middleValue) {
        if (minTemperature >= middleValue ||
            maxTemperature <= middleValue) {
          midOk = false;
        }

        temperature.temperature = middleValue;
        temperature.sensor_id = 1;
        temperature.time = middleTime;
        temperature.latitude = middleSensorValues[index]['location'].latitude;
        temperature.longitude = middleSensorValues[index]['location'].longitude;
        const converted = toETRSTM35FIN(temperature.latitude, temperature.longitude);
        const roadData = await getRoadData(converted.y, converted.x);
        temperature.roadNumber = roadData.road || null;
        temperature.roadPart = roadData.part || null;
        temperature.roadDistance = roadData.distance || null;

        temperatures.push(Object.assign({}, temperature));
      }

      if (rightValue) {
        if (minTemperature >= rightValue ||
            maxTemperature <= rightValue) {
          rightOk = false;
        }

        temperature.temperature = rightValue;
        temperature.sensor_id = 2;
        temperature.time = rightTime;
        temperature.latitude = rightSensorValues[index]['location'].latitude;
        temperature.longitude = rightSensorValues[index]['location'].longitude;
        const converted = toETRSTM35FIN(temperature.latitude, temperature.longitude);
        const roadData = await getRoadData(converted.y, converted.x);
        temperature.roadNumber = roadData.road || null;
        temperature.roadPart = roadData.part || null;
        temperature.roadDistance = roadData.distance || null;

        temperatures.push(Object.assign({}, temperature));
      }

      if (REMValue) {
        temperature.latitude = REMSensorValues[index]['location'].latitude;
        temperature.longitude = REMSensorValues[index]['location'].longitude;
        const converted = toETRSTM35FIN(temperature.latitude, temperature.longitude);
        const roadData = await getRoadData(converted.y, converted.x);
        temperature.roadNumber = roadData.road || null;
        temperature.roadPart = roadData.part || null;
        temperature.roadDistance = roadData.distance || null;

        if (minREMTemperature >= REMValue ||
            maxREMTemperature <= REMValue) {
          if (lastREMTemperatureOk) {
            const notice = {
              start_latitude: temperature.latitude,
              start_longitude: temperature.longitude,
              start_road_part: temperature.roadPart,
              start_road_distance: temperature.roadDistance,
            };
            currentREMNoticeId = await this.createNotice(notice);
            this.props.showAlertConfirm('Jyrsimen jälkeinen lämpötilan heitto: ' + REMValue + '°C',
                                        this.confirmNoticeText.bind(this, currentREMNoticeId));
            lastREMTemperatureOk = false;
          }
        }
        else {
          if (currentREMNoticeId != null) {
            const notice = {
              end_latitude: temperature.latitude,
              end_longitude: temperature.longitude,
              end_road_part: temperature.roadPart,
              end_road_distance: temperature.roadDistance,
            };
          
            this.updateNotice(currentREMNoticeId, notice);
            currentREMNoticeId = null;
          }

          lastREMTemperatureOk = true;
        }

        temperature.temperature = REMValue;
        temperature.sensor_id = 3;
        temperature.time = REMTime;

        if (currentREMNoticeId != null) {
          temperature.notice_id = currentREMNoticeId;
        }

        temperatures.push(Object.assign({}, temperature));
      }

      if ((!leftOk || !midOk || !rightOk)) {
        if (lastTemperatureOk) {
          let data;

          if (!leftOk) {
            data = temperatures[0];
          }
          else if (!midOk) {
            data = temperatures[1];
          }
          else {
            data = temperatures[2];
          }

          const notice = {
            start_latitude: data.latitude,
            start_longitude: data.longitude,
            start_road_part: data.roadPart,
            start_road_distance: data.roadDistance
          };

          currentNoticeId = await this.createNotice(notice);
          this.props.showAlertConfirm('Perän jälkeinen lämpötila heitto. Vasen: ' + leftValue + '°C' +
                                      ' Keski: ' + middleValue +  '°C Oikea: ' + rightValue + '°C',
                                      this.confirmNoticeText.bind(this, currentNoticeId));
          lastTemperatureOk = false;
        }
      }
      else {
        if (currentNoticeId != null) {
          let data;

          if (!leftOk) {
            data = temperatures[0];
          }
          else if (!midOk) {
            data = temperatures[1];
          }
          else {
            data = temperatures[2];
          }

          const notice = {
            end_latitude: data.latitude,
            end_longitude: data.longitude,
            end_road_part: data.roadPart,
            end_road_distance: data.roadDistance
          };
        
          this.updateNotice(currentNoticeId, notice);
          currentNoticeId = null;
        }
        
        lastTemperatureOk = true;
      }

      await this.sendSavedTemperatures();

      for (let index in temperatures) {
        let temperature = temperatures[index];

        if (index !== 3 && currentNoticeId != null) {
          temperature.notice_id = currentNoticeId;
        }

        try {
          await fetch('/temperature', 'POST', temperature);
        } catch(error) {
          if (error.toString() !== 'Error: 500') {
            if (localStorage['savedTemperatures'] == null) {
              localStorage['savedTemperatures'] = JSON.stringify([]);
            }
            let temperatures = JSON.parse(localStorage['savedTemperatures']);
            temperatures.push(temperature);
            localStorage['savedTemperatures'] = JSON.stringify(temperatures);
          }
        }
      }
    }

    localStorage.currentTemperatureNotice = currentNoticeId;
    localStorage.currentREMTemperatureNotice = currentREMNoticeId;
  }

  async sendSavedTemperatures() {
    if (localStorage['savedTemperatures'] != null) {
      let savedTemperatures = JSON.parse(localStorage['savedTemperatures']);
      savedTemperatures = savedTemperatures.filter((v,i) =>
                            savedTemperatures.findIndex(temperature => temperature.time === v.time) === i);
      let newTemperatures = savedTemperatures.slice();

      for (let index in savedTemperatures) {
        const savedTemperature = savedTemperatures[index];
        try {
          await fetch('/temperature', 'POST', savedTemperature);
          newTemperatures.splice(newTemperatures.findIndex(temperature => temperature['time'] === savedTemperatures['time']), 1);
        } catch(error) {
          if (error.toString() === 'Error: 500') {
            newTemperatures.splice(newTemperatures.findIndex(temperature => temperature['time'] === savedTemperatures['time']), 1);
          }
        }
      }
      if (newTemperatures.length === 0) {
        localStorage.removeItem('savedTemperatures');
      }
      else {
        localStorage['savedTemperatures'] = JSON.stringify(newTemperatures);
      }
    }
  }

  async getMapPaths(masses, site) {
    this.setState({ mapPaths: [] });

    let paths = [];
    let x = 0;
    let y = 0;
    let z = 0;
    let coordinateCount = 0;
    let zoom = null;
    let position = null;
    let allPoints = [];

    if (site != null) {
      try {
        allPoints = await fetch('/points/mass/site/' + site);
      } catch(err) {}
    }

    for (let i = 0; i < masses.size; i++) {
      const mass = masses.get(i);

      for (let p = 0; p < mass.get('paths').size; p++) {
        const path =  mass.get('paths').get(p);

        if (!path.get('start_latitude')) continue;

        const startLatitude = toRadians(path.get('start_latitude'));
        const startLongitude = toRadians(path.get('start_longitude'));
        x += Math.cos(startLatitude) * Math.cos(startLongitude);
        y += Math.cos(startLatitude) * Math.sin(startLongitude);
        z += Math.sin(startLatitude);
        coordinateCount++;

        let positions = [];

        if (path.get('end_latitude')) {
          const allPathPoint = allPoints.filter(point => point.path_id === path.get('id'));

          if (allPathPoint.length !== 0) {
            allPathPoint.forEach(point => {
              positions.push([point.latitude, point.longitude])
            });
            if (allPathPoint[allPathPoint.length - 1].road_distance !== path.get('end_distance')) {
              positions.push([path.get('end_latitude'), path.get('end_longitude')])
            }
          }
          else {
            positions = [[path.get('start_latitude'), path.get('start_longitude')],
                        [path.get('end_latitude'), path.get('end_longitude')]];
          }

          const endLatitude = toRadians(path.get('end_latitude'));
          const endLongitude = toRadians(path.get('end_longitude'));
          x += Math.cos(endLatitude) * Math.cos(endLongitude);
          y += Math.cos(endLatitude) * Math.sin(endLongitude);
          z += Math.sin(endLatitude);
          coordinateCount++;
        }
        else {
          positions = [[path.get('start_latitude'), path.get('start_longitude')],
                      [path.get('start_latitude'), path.get('start_longitude')]];
        }

        if (path.get('direction') === 2) {
          if (path.get('start_part') > path.get('end_part') ||
              path.get('start_distance') > path.get('end_distance')) {
            positions.reverse();
          }
          positions = await this.get2DirectionPath(positions);
        }

        paths[path.get('id')] = positions;
      }
    }

    if (coordinateCount !== 0) {
      zoom = 15;

      x = x / coordinateCount;
      y = y / coordinateCount;
      z = z / coordinateCount;

      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);

      position = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];
    }
    else if (this.props.yourLaditude != null) {
      zoom = 15;
      position = [this.props.yourLaditude, this.props.yourLongitude];
    }

    this.setState({
      mapPaths: paths,
      mapZoom: zoom,
      mapPosition: position
    });
  }

  async get2DirectionPath(path) {
    let newPath = []
    let lastAngle;

    if (path.length > 1 && path[0][0] !== path[1][0]) {
      for (let index in path) {
        index = parseInt(index, 10);
        const point = path[index];

        if (index !== path.length - 1) {
          const point2 = path[index + 1];
          lastAngle = this.getOffSetAngle(point[0], point[1], point2[0], point2[1]);
        }

        const newCoordinate = this.getNewCoordinatesByAngle(lastAngle, point[0], point[1])
        newPath.push(newCoordinate)
      }
    }
    else {
      try {
        const converted = toETRSTM35FIN(path[0][0], path[0][1]);
        let roadData = await getRoadData(converted.y, converted.x, 10);
        const roadNumber = roadData.road;
        const roadPart = roadData.part;
        let roadDistance = roadData.distance;
        const anotherPointDistance = 10;

        if (roadDistance < anotherPointDistance) {
          roadDistance = roadDistance + anotherPointDistance;
        }
        else {
          roadDistance = roadDistance - anotherPointDistance;
        }

        let coordinates = await getRoadCoordinates(roadNumber, roadPart, roadDistance);

        const anotherCoordinates = toWGS84(coordinates.y, coordinates.x);

        const angle = this.getOffSetAngle(anotherCoordinates.latitude, anotherCoordinates.longitude,
                                          path[0][0], path[0][1]);
                
        const newCoordinate = this.getNewCoordinatesByAngle(angle, path[0][0], path[0][1])
        newPath = [newCoordinate, newCoordinate]
      } catch(error) {
        return path;
      }
    }

    return newPath;
  }

  getOffSetAngle(lat1, lon1, lat2, lon2) {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) *
              Math.cos(lat2) * Math.cos(dLon);
    let angle = Math.atan2(y, x);
    angle = toDegrees(angle);
    angle = (angle + 360 - 90) % 360;
    return toRadians(angle);
  }

  getNewCoordinatesByAngle(angle, latitude, longitude) {
    const R = 6378100; // Radius of the Earth
    const distanceBetween = 5;

    const lat1 = toRadians(latitude);
    const lon1 = toRadians(longitude);

    let newLatitude = Math.asin(Math.sin(lat1) * Math.cos(distanceBetween / R) +
                Math.cos(lat1) * Math.sin(distanceBetween / R) * Math.cos(angle));

    let newLongitude = lon1 + Math.atan2(Math.sin(angle) * Math.sin(distanceBetween / R) * Math.cos(lat1),
                                      Math.cos(distanceBetween / R) - Math.sin(lat1) * Math.sin(newLatitude));

    newLatitude = toDegrees(newLatitude);
    newLongitude = toDegrees(newLongitude);

    return [newLatitude, newLongitude];
  }

  acceptTargetWidth() {
    this.setState({ setTargetWidth: parseFloat(this.state.targetWidth) });
  }

  async createNotice(notice) {
    let date = new Date();
    const timezoneOffset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() - timezoneOffset);

    let newNotice = {
      construction_site_id: this.props.selectedConstructionSite.get('id'),
      time: date.toISOString().replace('Z', ''),
      start_latitude: notice.start_latitude,
      start_longitude: notice.start_longitude,
      start_road_part: notice.start_road_part,
      start_road_distance: notice.start_road_distance,
    };

    try {
      newNotice = await fetch('/notice', 'POST', newNotice);
    } catch(error) {

    }

    return newNotice.id;
  }

  async updateNotice(id, updatedNotice) {
    try {
      await fetch('/notice/' + id, 'PATCH', updatedNotice);
    } catch(error) {

    }
  }

  async confirmNoticeText(id, noticeText) {
    this.updateNotice(id, {notice: noticeText});
  }

  toggleDeepnessCalibrationInfo() {
    window.alert('Syötä ajankohta jolloin kone on ollut varmasti tasaisella alustalla (Paikka jossa, missä syvyys on ollut 0)');
  }

  render() {
    return (
      <div className='container'>
        <h1>Massan lisäys</h1>
        <div className='button-area'>
          <button className='button-view' onClick={this.setView.bind(this, 0)}
                  disabled={this.state.view === 0}>
            Perustiedot
          </button>
          { this.state.sensor ?
            <button className='button-view' onClick={this.setView.bind(this, 5)}
                    disabled={this.state.view === 5}>
              Sensoritiedot
            </button>
            :
            null
          }
          { this.state.REM ?
            <button className='button-view' onClick={this.setView.bind(this, 4)}
                    disabled={this.state.view === 4}>
              REM-tiedot
            </button>
            :
            null
          }
          <button className='button-view' onClick={this.setView.bind(this, 1)}
                  disabled={this.state.view === 1}>
            Levitystiedot
          </button>
          <button className='button-view' onClick={this.setView.bind(this, 2)}
                  disabled={this.state.view === 2}>
            Seurantatiedot
          </button>
          <button className='button-view' onClick={this.setView.bind(this, 3)}
                  disabled={this.state.view === 3}>
            Kartta
          </button>
        </div>
        <View view={this.state.view} changeState={this.changeState} width={this.state.width}
              roadway={this.state.roadway} direction={this.state.direction} lane={this.state.lane}
              startRoadPart={this.state.road_part} thickness={this.state.thickness}
              startRoadPole={this.state.road_pole}
              road={this.props.selectedConstructionSite ? this.props.selectedConstructionSite.get('road_number') : '-'} 
              newMass={this.onSubmit} useLocation={this.useLocation}
              mass={this.state.truckMass} smallAreas={this.state.smallAreas}
              levelingMass={this.state.levelingMass} attentions={this.state.attentions}
              makeEndForPath={this.onMakeEndForPath} newPath={this.newPath}
              massPerSquare={this.state.massPerSquare}
              dailyTruckMass={this.state.dailyTruckMass}
              dailySmallAreas={this.state.dailySmallAreas} dailyLevelingMass={this.state.dailyLevelingMass}
              dailyCount={this.state.dailyCount} lastPart={this.state.lastMassPart}
              lastPole={this.state.lastMassPole} truckRegisterNumber={this.state.truckRegisterNumber}
              selectedLoad={this.props.selectedLoad} resetTruck={this.resetTruck}
              masses={this.props.masses} selectedConstructionSite={this.props.selectedConstructionSite} dailyMass={this.state.dailyMass}
              loading={this.state.loadingMasses} confirmRemoveMass={this.confirmRemoveMass} confirmRemoveLocalMass={this.confirmRemoveLocalMass}
              removeLastPath={this.confirmRemoveLastPath}
              location_road_number={this.state.location_road_number} location_road_part={this.state.location_road_part}
              location_road_pole={this.state.location_road_pole} accuracy={this.state.accuracy}
              locationTime={this.state.locationTime} latitude={this.state.latitude} longitude={this.state.longitude}
              mapPaths={this.state.mapPaths} mapZoom={this.state.mapZoom} mapPosition={this.state.mapPosition}
              showMessage={this.props.showMessage} changeMass={this.goChangeMass} dailyCurrentMassPerSquare={this.state.dailyCurrentMassPerSquare}
              loads={this.props.loads} wholeMass={this.state.wholeMass} wholeCurrentMassPerSquare={this.state.wholeCurrentMassPerSquare}
              autoGPS={this.state.autoGPS} disableSubmit={this.state.disableSubmit} disableNewPath={this.state.disableNewPath}
              toggleAddSmallArea={this.toggleAddSmallArea} resetCrosshairValue={this.resetCrosshairValue}
              crosshairValue={this.state.crosshairValue} setCrosshairValue={this.setCrosshairValue}
              graphData={this.state.graphData} graphData2={this.state.graphData2}
              graphWholeData={this.state.graphWholeData} lastTemperature={this.state.lastTemperature}
              temperature={this.state.temperature} REM={this.state.REM} groove={this.state.groove}
              lastWidth={this.state.lastWidth} lastMassPerSquare={this.state.lastMassPerSquare}
              lastThickness={this.state.lastThickness} lastDirection={this.state.lastDirection}
              lastRoadway={this.state.lastroadway} lastLane={this.state.lastlane}
              millingDeepnessRight={this.state.millingDeepnessRight} millingDeepnessLeft={this.state.millingDeepnessLeft}
              REMTemperature={this.state.REMTemperature} lastMillingDeepnessRight={this.state.lastMillingDeepnessRight}
              lastMillingDeepnessLeft={this.state.lastMillingDeepnessLeft} lastREMTemperature={this.state.lastREMTemperature}
              lastLocationOnRoad={this.state.lastLocationOnRoad} paths={this.state.paths}
              sensor={this.state.sensor} toggleSensors={this.toggleSensors}
              disableEndForPath={this.state.disableEndForPath}
              targetWidth={this.state.targetWidth} setTargetWidth={this.state.setTargetWidth}
              acceptTargetWidth={this.acceptTargetWidth}

              widthSensor={this.state.widthSensor} widthSensorValue={this.state.widthSensorValue}
              toggleWidthSensor={this.toggleWidthSensor} widthSensorLeftValue={this.state.widthSensorLeftValue}
              widthSensorRightValue={this.state.widthSensorRightValue} lengthBetweenWidthSensors={this.state.lengthBetweenWidthSensors}
              
              toggleDeepnessSensor={this.toggleDeepnessSensor}
              deepnessAfterSensorLeftValue={this.state.deepnessAfterSensorLeftTrueValue}
              deepnessAfterSensorRightValue={this.state.deepnessAfterSensorRightTrueValue}
              deepnessOriginalSensorLeftValue={this.state.deepnessOriginalSensorLeftTrueValue}
              deepnessOriginalSensorRightValue={this.state.deepnessOriginalSensorRightTrueValue}
              deepnessZeroLeftOriginal={this.state.deepnessZeroLeftOriginal}
              deepnessZeroLeftAfter={this.state.deepnessZeroLeftAfter}
              deepnessZeroRightOriginal={this.state.deepnessZeroRightOriginal}
              deepnessZeroRightAfter={this.state.deepnessZeroRightAfter}
              deepnessSensor={this.state.deepnessSensor}
              deepnessLeftCalibratedValue={this.state.deepnessLeftCalibratedValue}
              deepnessSensorLeftValue={this.state.deepnessSensorLeftValue}
              deepnessSensorRightValue={this.state.deepnessSensorRightValue}
              deepnessCalibrationDate={this.state.deepnessCalibrationDate}
              deepnessCalibrationTime={this.state.deepnessCalibrationTime}
              toggleDeepnessCalibrationInfo={this.toggleDeepnessCalibrationInfo}
              reverseLeftDeepness={this.state.reverseLeftDeepness}
              reverseRightDeepness={this.state.reverseRightDeepness}
              deepnessLeftOriginalCalibration={this.state.deepnessLeftOriginalCalibration}
              deepnessLeftAfterCalibration={this.state.deepnessLeftAfterCalibration}
              deepnessRightOriginalCalibration={this.state.deepnessRightOriginalCalibration}
              deepnessRightAfterCalibration={this.state.deepnessRightAfterCalibration}

              toggleTemperatureSensor={this.toggleTemperatureSensor}
              temperatureSensor={this.state.temperatureSensor}
              temperatureLeftValue={this.state.temperatureSensorLeftValue}
              temperatureMiddleValue={this.state.temperatureSensorMiddleValue}
              temperatureRightValue={this.state.temperatureSensorRightValue}
              temperatureREMValue={this.state.temperatureSensorREMValue}
              sensorDevices={this.state.sensorDevices}

              selectedSensorDevice={this.state.selectedSensorDevice}
              lastSelectedSensorDevice={this.state.lastSelectedSensorDevice}
              lastSensortime={this.state.lastSensortime}
              lastSensorLatitude={this.state.lastSensorLatitude}
              lastSensorLongitude={this.state.lastSensorLongitude}
              
              organizationId={this.props.organizationId}
              store={this.props.store} />
        <ChangeMassView mass={this.state.changingMass} clear={this.clearChangeMass}
                        changeMass={this.changeMass} changeLocalMass={this.changeLocalMass}
                        locationValues={locationValues} />
        <AccuracyFixer show={this.state.showAccuracyFixer} toggle={this.toggleAccuracyFixer}
                       submit={this.state.accuracySubmit} accuracy={this.state.accuracy}/>
        <AddSmallArea addingSmallArea={this.state.addingSmallArea} changeState={this.changeState} 
                      addSmallArea={this.addSmallArea} selectedMass={this.state.selectedMass}
                      toggle={this.toggleAddSmallArea} masses={this.props.masses}
                      show={this.state.showAddSmallArea} selectMass={this.selectMass} />
        <Weather ref={element => this.weather = element} latitude={this.state.latitude} longitude={this.state.longitude}
                 notVisible={true} />
        <div id='top-info'>
          { this.state.lastPath == null || this.state.lastPath.get('end_part') ?
            null
            :
            <div>
              <span className='top-text'>
                Aloitus:
                <strong>
                  {' ' + this.state.lastPath.get('start_part') + ' / ' + this.state.lastPath.get('start_distance')}
                 </strong>
              </span>
              { this.state.autoGPS ?
                  <div>
                    <span className='distance-info'>
                      Matka: <strong>{this.state.traveledDistance} m</strong>
                    </span>
                  </div>
                  : null
                }
            </div>
          }
          <span className='top-text'>
            Kg/m²: <strong>{this.state.massPerSquare}</strong>
          </span>
        </div>
        { this.state.loadingMasses ? <div className="main loader"></div> : null }
      </div>
    );
  }
}

export default connect(state => ({
  masses: state.mass.get('masses'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
  selectedLoad: state.loads.get('selectedLoad'),
  loads: state.loads.get('loads'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), { addMass, changeMass, removeMassById, showNotice, showConfirm, showMessage,
      selectTruck, clearMasses, addMasses, selectLoad, updateLastPath,
      addPathToLastMass, removeLastPath, showAlertConfirm })(MassNew);
