import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice, ContractSelect, ConstructionSiteSelect, FindSelect } from 'components';
import { fetch, stateValueParser } from '../utils';


class TrucksNew extends React.Component {

  constructor(props) {
    super(props);

    this.getTrucks();

    this.state = {
      redirect: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      defaultMass: 0,
      trucks: []
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    this.setState({[propertyName]: value});
  }

  getTrucks() {
    fetch('/trucks/').then(data => {
      this.setState({
        trucks: data
      });
    });
  }

  async onSubmit() {
    const registerNumber = this.truckselect.state.value;

    if (registerNumber === '') {
      this.props.showNotice('Rekisterinumeroa ei ole syötetty', 'Warning');
      return;
    }

    this.setState({ submiting: true });

    const creatingTruck = registerNumber.toUpperCase();
    const site = this.props.selectedConstructionSite != null ? this.props.selectedConstructionSite.get('id') : null;
    let existingTruck = this.state.trucks.find(truck => truck.register_number === creatingTruck);

    if (existingTruck == null) {
      const mass = parseFloat(this.state['defaultMass']);
      if (isNaN(mass)) {
        this.props.showNotice('Massa määrä on virheellinen', 'Warning');
        this.setState({ submiting: false });
        return;
      }
      if (mass === 0) {
        this.props.showNotice('Massa määrää ei ole syötetty', 'Warning');
        this.setState({ submiting: false });
        return;
      }
      const data = {
        register_number: creatingTruck, 
        default_mass: mass
      };
      try {
        await fetch('/trucks/', 'POST', data);
        const trucks = await fetch('/trucks/');
        existingTruck = trucks.find(truck => truck.register_number === creatingTruck);
      } catch(error) {
        this.setState({
          submiting: false
        });
        this.props.showNotice('Lisääminen epäonnistui', 'Error');
        return;
      }
    }

    const wantedSite = await fetch('/constructionsites/' + site + '/');
    let trucks = wantedSite.trucks;

    if (trucks.find(truck => truck.register_number === creatingTruck)) {
      this.props.showNotice('Kyseinen rekka on jo olemassa tässä kohteessa.', 'Warning');
    }
    else {
      let truckIDs = [];

      trucks.forEach(truck => (
        truckIDs.push(truck.id)
      ));

      truckIDs.push(existingTruck.id);

      const editedSite = {
        id: wantedSite['id'],
        trucks: truckIDs 
      };

      try {
        await fetch('/constructionsites/' + editedSite['id'] + '/', 'PATCH', editedSite);
        this.props.showNotice('Rekka lisätty kohteeseen', 'Ok');
        this.setState({
          redirect: true
        });
      } catch(error) {
        this.props.showNotice('Palvelin virhe', 'Error');
      }
    }

    this.setState({
      submiting: false
    });
  }
  
  render() {
    if (this.state.redirect) return <Navigate to='/trucks' push/>;
    return (
      <div className="container">
        <h1>Lisää rekka</h1>
        <div className='row'>
          <div className='column'>
            <label htmlFor='registerNumber'>Rekisterinumero:</label>
            <FindSelect ref={element => this.truckselect = element} items={this.state.trucks}
                        data={['id', 'register_number']}/>
          </div>
          <div className='column'>
            <label htmlFor='defaultMass'>Vakio massa Määrä:</label>
            <input id='defaultMass' type='tel'
                  value={this.state.defaultMass}
                  onChange={this.changeState.bind(this, 'defaultMass', 'float', 0.0)} required/>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="column">
              <ContractSelect store={this.props.store} />
            </div>
            <div className="column">
              <ConstructionSiteSelect store={this.props.store} />
            </div>
          </div>
          <button className="button-primary" onClick={this.onSubmit}
                  disabled={this.state.submiting}>
            Lisää rekka
          </button>
          <Link to="/trucks">
            <button className="button-outline">Peruuta</button>
          </Link>
        </div>
      </div>
    );
  }
}


export default connect(state => ({
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showNotice })(TrucksNew);
