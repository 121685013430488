import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import { List, TruckSelect, selectTruck, showNotice, showConfirm,
         MapTiles } from 'components';
import { fetch, stateValueParser, toETRSTM35FIN } from '../utils.js';

const paddedNumber = number => number <= 99 ? ('0'+number).slice(-2) : number;

export const RoadwaySelect = props => (
  <div>
    <label>Ajorata</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      0
      <input type='radio' name='roadway' value='0'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 0}/>
    </label>
    <label className='radio'>
      1
      <input type='radio' name='roadway' value='1'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 1}/>
    </label>
    <label className='radio'>
      2
      <input type='radio' name='roadway' value='2'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 2}/>
    </label>
  </div>
);

export const DirectionSelect = props => (
  <div>
    <label>Suunta</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      1
      <input id='direction1' type='radio' name='direction' value='1'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 1}/>
    </label>
    <label className='radio'>
      2
      <input id='direction2' type='radio' name='direction' value='2'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 2}/>
    </label>
  </div>
);

export const LaneSelect = props => (
  <div>
    <label>Kaista</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      1
      <input type='radio' name='line' value='1'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 1}/>
    </label>
    <label className='radio'>
      2
      <input type='radio' name='line' value='2'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 2}/>
    </label>
  </div>
);

export const WidthSelect = props => (
  <div>
    <label htmlFor='width'>Leveys (m)</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last + ' m'}<br/></div> : null}
    <input id='width' type='tel'
           onChange={props.onChange.bind(this, 'width', 'float', 0.0)} value={props.value} required/>
  </div>
);

export const MassPerSquareSelect = props => (
  <div>
    <label htmlFor='mass-per-square'>kg / m²</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last + ' kg / m²'}<br/></div> : null}
    <input id='mass-per-square' type='tel'
           onChange={props.onChange.bind(this, 'massPerSquare', 'float', 0.0)}
           value={props.value} required/>
  </div>
);

export const ThicknessSelect = props => (
  <div>
    <label htmlFor='thickness'>Paksuus (mm)</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last + ' mm'}<br/></div> : null}
    <input id='thickness' type='tel'
           onChange={props.onChange.bind(this, 'thickness', 'float', 0.0)} value={props.value} required/>
  </div>
);

export const AccuracyFixer = props => {
  if (!props.show) return null;
  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='accuracy-modal'>
        <h4>Odotetaan sijainnin parantumista epätarkkuuden takia...</h4>
        <h5>Tämän hetkinen tarkkuus: {props.accuracy} m</h5>
        <div className='loader'/>
        <br/>
        <button onClick={props.submit}>
          Hyväksy joka tapauksessa
        </button>
        <button onClick={props.toggle}>
          Syötä manuaalisesti
        </button>
      </div>
    </div>
  );
}

export class GrooveLocation extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      show: false,
      location: null
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillMount() {
    if (this.props.value != null) {
      this.setState({
        location: this.props.value
      });
    }
    else if (typeof(Storage) !== 'undefined') {
      this.setState({
        location: localStorage.locationOnRoad || null,
      });
    }
  }

  toggle() {
    this.setState({show: !this.state.show});
  }

  selectLocation(location) {
    this.setState({location: location});
    localStorage.locationOnRoad = location;
  }

  render() {
    if (!this.state.show) {
      return (
        <div>
          <label htmlFor='location-on-road'>Sijainti ajoradalla:</label>
          Edellinen: {this.props.lastValue ? this.props.values['L' + this.props.lastValue] : '-'}
          <input id='location-on-road' type='text'
                 onClick={this.toggle}
                 value={this.props.values[this.state.location] || 'Valitse sijainti'}
                 readOnly />
        </div>
      );
    }
    return (
      <div onClick={this.toggle} className='modal'>
        <div onClick={e => e.stopPropagation()} id='groove-modal'>
          <h4>Valitse kohta</h4>
          <div id='groove-select'>
            <hr />
            { Object.keys(this.props.values).map(key => (
              <p className={'groove-option' + (this.state.location === key ? ' selected' : '' )}
                 onClick={this.selectLocation.bind(this, key)}>
                {this.props.values[key]}
              </p>
              ))
            }
          </div>
          <br/>
          <button onClick={this.toggle}>
            Ok
          </button>
        </div>
      </div>
    );
  }
}

class ChangeMassView extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      massPerSquare: null,
      thickness: null,
      truckRegisterNumber: null,
      truckMass: null,
      small_areas: null,
      leveling_mass: null,
      attentions: null,
      temperature: null,
      millingDeepnessRight: null,
      millingDeepnessLeft: null,
      REMTemperature: null,
      confirmedRoad: false,
      firstTime: true
    };

    this.changeState = this.changeState.bind(this);
    this.confirmRoadInfo = this.confirmRoadInfo.bind(this);
    this.doChangeMass = this.doChangeMass.bind(this);
  }

  componentDidUpdate(lastProps) {
    if (this.props.mass == null) return;

    if (lastProps.mass === this.props.mass) {
      if (this.props.selectedTruck != null &&
          this.props.selectedTruck !== lastProps.selectedTruck) {
        if (this.state.firstTime) {
          this.setState({
            firstTime: false
          });
          return;
        }
        this.setState({
          truckRegisterNumber: this.props.selectedTruck.get('register_number'),
          truckMass: this.props.selectedTruck.get('default_mass'),
          firstTime: false
        });
      }
      return;
    }

    this.props.selectTruck(this.props.mass.get('truck'));

    this.setState({
      massPerSquare: this.props.mass.get('mass_per_square'),
      thickness: this.props.mass.get('thickness'),
      truckMass: this.props.mass.get('truck_mass'),
      smallAreas: this.props.mass.get('small_areas'),
      levelingMass: this.props.mass.get('leveling_mass'),
      attentions: this.props.mass.get('attentions'),
      temperature: this.props.mass.get('temperature'),
      millingDeepnessRight: this.props.mass.get('milling_deepness_right'),
      millingDeepnessLeft: this.props.mass.get('milling_deepness_left'),
      REMTemperature: this.props.mass.get('REM_temperature')
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    this.setState({[propertyName]: value});
  }

  confirmRoadInfo() {
    this.setState({
      confirmedRoad: true
    }, () => this.doChangeMass());
  }

  async doChangeMass() {
    if (this.state.massPerSquare === "0" || this.state.massPerSquare === "") {
      this.props.showNotice('Tavoite kg / m² ei ole annettu', 'Warning');
      return;
    }

    if (this.props.mass.get('load') == null && this.props.selectedTruck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    if (this.state.truckMass === 0) {
      this.props.showNotice('Kuorman massaa ei ole annettu', 'Warning');
      return;
    }

    let mass = this.props.mass;
    mass = mass.set('mass_per_square', parseFloat(this.state.massPerSquare));
    mass = mass.set('thickness', parseFloat(this.state.thickness));

    if (mass.get('load') == null) {
      mass = mass.set('truck', null);
      mass = mass.set('truckId', this.props.selectedTruck.get('id'));
      mass = mass.set('truck_mass', parseFloat(this.state.truckMass));
    }

    mass = mass.set('small_areas', parseFloat(this.state.smallAreas || 0));
    mass = mass.set('leveling_mass', parseFloat(this.state.levelingMassv || 0));
    mass = mass.set('attentions', this.state.attentions);

    if (this.state.millingDeepnessRight) {
      mass = mass.set('milling_deepness_right', parseFloat(this.state.millingDeepnessRight));
      mass = mass.set('milling_deepness_left', parseFloat(this.state.millingDeepnessLeft));
      mass = mass.set('REM_temperature', parseFloat( this.state.REMTemperature));
    }

    if (this.state.locationOnRoad) {
      mass = mass.set('location_on_road', parseInt(this.grooveLocation.state.location.substring(1), 10));
    }

    if (mass.get('not_saved')) {
      this.props.changeLocalMass(mass);
    }
    else {
      this.props.changeMass(mass);
    }
  }

  render() {
    if (this.props.mass == null) return null;

    return (
      <div onClick={this.props.clear} className='modal'>
        <div onClick={e => e.stopPropagation()} id='load-data-modal'>
          <h3 className='center'>Massan muokkaus</h3>
          <div className='row'>
            <div className='column'>
              <ThicknessSelect id='thickness' onChange={this.changeState} value={this.state.thickness}/>
            </div>
            <div className='column'>
              <label htmlFor='truck'>Rekka</label>
              { this.props.mass.get('load') == null ?
                <TruckSelect required store={this.props.store}/>
              :
                <input id='truck' type='text' value={this.state.truckRegisterNumber}
                      readOnly/>
              }
            </div>
            <div className='column'>
              <label htmlFor='mass'>Kuorma (Tonnit)</label>
              { this.props.mass.get('load') == null ?
                <input id='mass' type='tel'
                      value={this.state.truckMass}
                      onChange={this.changeState.bind(this, 'truckMass', 'float', 0.0)} required/>
              :
                <input id='mass' type='tel'
                      value={this.state.mass}
                      readOnly/>
              }
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <label htmlFor='temperature'>Lämpötila (°C)</label>
              <input id='temperature' type='tel'
                    value={this.state.temperature || ''}
                    onChange={this.changeState.bind(this, 'temperature', 'float', 0.0)}/>
            </div>
            <div className='column'>
              <label htmlFor='smallArea'>Pienalueet (m²)</label>
              <input id='smallArea' type='tel'
                    value={this.state.smallAreas}
                    onChange={this.changeState.bind(this, 'smallAreas', 'float', 0.0)}/>
            </div>
            <div className='column'>
              <label htmlFor='leveling'>Tasaus (Tonnit)</label>
              <input id='leveling' type='tel'
                    value={this.state.levelingMass}
                    onChange={this.changeState.bind(this, 'levelingMass', 'float', 0.0)}/>
            </div>
            <div className='column'>
              <label htmlFor='attentions'>Huomiot</label>
              <input id='attentions' type='text' value={this.state.attentions || ''}
                    onChange={this.changeState.bind(this, 'attentions', 'string', '')}/>
            </div>
          </div>
          { this.state.millingDeepnessRight ?
            <div className='row'>
              <div className='column'>
                <label htmlFor='millingDepthRight'>Jyrsintäsyvyys (mm) oikea</label>
                <input id='millingDepthRight' type='tel'
                      value={this.state.millingDeepnessRight || ''}
                      onChange={this.changeState.bind(this, 'millingDeepnessRight', 'float', 0.0)} />
              </div>
              <div className='column'>
                <label htmlFor='millingDepthLeft'>Jyrsintäsyvyys (mm) vasen</label>
                <input id='millingDepthLeft' type='tel'
                      value={this.state.millingDeepnessLeft || ''}
                      onChange={this.changeState.bind(this, 'millingDeepnessLeft', 'float', 0.0)} />
              </div>
              <div className='column'>
                <label htmlFor='REMTemperature'>Kuumennetun alustan lämpötila (°C)</label>
                <input id='REMTemperature' type='tel'
                      value={this.state.REMTemperature || ''}
                      onChange={this.changeState.bind(this, 'REMTemperature', 'float', 0.0)}/>
              </div>
            </div>
            :
            null
          }
          <div className='row'>
            <div className='column'>
              <button onClick={this.doChangeMass}>
                Tallenna
              </button>
            </div>
            <div className='column'>
              <button className='button-outline' onClick={this.props.clear}>
                Peruuta
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  selectedTruck: state.truckSelect.get('selectedTruck'),
  trucks: state.truckSelect.get('trucks'),
}), { selectTruck, showNotice, showConfirm })(ChangeMassView);


export const LoadDataOfMass = props => {
  if (props.data.length === 0) return null;

  let totalStone1 = 0;
  let totalStone2 = 0;
  let totalStone3 = 0;
  let totalStone4 = 0;
  let totalBitumen = 0;
  let totalPoly = 0;
  let totalFiller = 0;
  let totalCrush = 0;
  let totalFibre = 0;
  let totalMass = 0;
  let totalSize = 0;

  for (let index in props.data) {
    totalStone1 += props.data[index]['stone1'];
    totalStone2 += props.data[index]['stone2'];
    totalStone3 += props.data[index]['stone3'];
    totalStone4 += props.data[index]['stone4'];
    totalBitumen += props.data[index]['bitumen'];
    totalPoly += props.data[index]['dust'];
    totalFiller += props.data[index]['filler'];
    totalCrush += props.data[index]['crush'];
    totalFibre += props.data[index]['fibre'];
    totalMass += props.data[index]['mass'];;
    totalSize += props.data[index]['size'];
  }

  return (
    <div onClick={props.clear} className='modal'>
      <div id='load-data-modal'>
        <h4 className='center'>Kuorman tiedot (Tonneina)</h4>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                Kivi 1
              </th>
              <th>
                Kivi 2
              </th>
              <th>
                Kivi 3
              </th>
              <th>
                Kivi 4
              </th>
              <th>
                Bitumi
              </th>
              <th>
                Pöly
              </th>
              <th>
                Täyteaine
              </th>
              <th>
                Rouhe
              </th>
              <th>
                Kuitu
              </th>
              <th>
                Tonnit yhteensä
              </th>
              <th>
                Annoskoko
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Yhteensä:</b>
              </td>
              <td>
                {Math.round(totalStone1*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone2*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone3*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone4*10000)/10000}
              </td>
              <td>
                {Math.round(totalBitumen*10000)/10000}
              </td>
              <td>
                {Math.round(totalPoly*10000)/10000}
              </td>
              <td>
                {Math.round(totalFiller*10000)/10000}
              </td>
              <td>
                {Math.round(totalCrush*10000)/10000}
              </td>
              <td>
                {Math.round(totalFibre*10000)/10000}
              </td>
              <td>
                {Math.round(totalMass*10000)/10000}
              </td>
              <td>
                {Math.round(totalSize*10000)/10000}
              </td>
            </tr>
          </tbody>
        </table>
        <h5 className='center'>Erittely lastauksista:</h5>
        <List id='test'
              header={['Aika', 'Rekka', 'Resepti', 'Kivi 1', 'Kivi 2', 'Kivi 3',
                      'Kivi 4', 'Bitumi', 'Pöly', 'Täyteaine', 'Rouhe', 'Kuitu',
                      'Lämpötila', 'Tonnit', 'Annoskoko', 'Huomiot']}
              fields={['time_stamp#time', 'truck', 'recipe', 'stone1#round', 'stone2#round',
                      'stone3#round', 'stone4#round', 'bitumen#round', 'dust#round', 'filler#round',
                      'crush#round', 'fibre#round', 'tempature#round', 'mass#round', 'size', 'attentions']}
              data={fromJS(props.data)}/>
      </div>
    </div>
  );
};


export class MapView extends React.Component {

  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.getTrucks = this.getTrucks.bind(this);
    this.showLoadDataOfMass = this.showLoadDataOfMass.bind(this);
    this.clearLoadDataOfMass = this.clearLoadDataOfMass.bind(this);
    this.selectLine = this.selectLine.bind(this);

    this.position = [64.1, 26.5];
    this.zoom = 6;
    this.truckUpdateInterval = null;

    const height = window.innerHeight * 0.6;

    this.state = {
      loadDataOfMass: [],
      showedWarning: false,
      directions: [1, 2],
      trucks: [],
      lines: [],
      height: height,
      mapTilesUrl: '',
      mapTilesAttribution: '',
      maxZoom: 16
    };
  }

  componentDidMount() {
    if (this.props.mapPosition) {
      this.position = this.props.mapPosition;
      this.zoom = this.props.mapZoom;
    }
    
    window.addEventListener("resize", this.updateDimensions);
    this.update(this.props, this.state);
    this.getTrucks();
    this.truckUpdateInterval = setInterval(this.getTrucks, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    clearInterval(this.truckUpdateInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    const mapCenter = this.map.leafletElement.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng)
    const mapTiles = MapTiles(converted.x, converted.y);

    if (this.state.mapTilesUrl !== mapTiles.url) {
      this.setState({
        mapTilesUrl: mapTiles.url,
        mapTilesAttribution: mapTiles.attribution,
        maxZoom: mapTiles.maxZoom
      });
    }

    if (this.state.directions !== prevState.directions ||
        this.state.selectedLine !== prevState.selectedLine ||
        this.props.mapPaths !== prevProps.mapPaths) {
      this.update(this.props, this.state);
    }
  }

  async update(props, state) {
    if (props.mapPaths.length === 0) {
      this.setState({ lines: [] })
      return;
    }

    let lines = [];

    for (let i = 0; i < props.masses.size; i++) {
      const mass = props.masses.get(i);

      for (let p = 0; p < mass.get('paths').size; p++) {
        const path =  mass.get('paths').get(p);

        if (!(state.directions.includes(path.get('direction'))) || !path.get('start_latitude')) continue;

        const date = new Date(path.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
        + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

        let color;

        if (state.selectedLine === path.get('id')) {
          color = '#00FFFF';
        }
        else if (path.get('direction') === 1) {
          color = '#0000FF';
        }
        else {
          color = '#00FF00';
        }

        const text = (
          <span>
            {time}
            <br/>
            Aloitus: {path.get('road')} / Osa: {path.get('start_part')} / Paalu: {path.get('start_distance')}
            <br/>
            Lopetus: {path.get('road')} / Osa: {path.get('end_part') || '- '} / Paalu: {path.get('end_distance') || '-'}
            <br/>
            Ajorata: {path.get('roadway')}
            <br/>
            Suunta: {path.get('direction')}
            <br/>
            Kaista: {path.get('lane')}
            <br/>
            { path.get('location_on_road') ?
              <div>
                Sijainti ajoradalla: {props.grooveValues['L' + path.get('location_on_road')]}
              </div>
              : null
            }
            Rekisterinumero: {mass.get('truck').get('register_number')}
            <br/>
            { mass.get('paths').size > 1 ?
              <div>
                Arvio käytetystä massasta: {Math.round(path.get('estimated_mass') * 100) / 100}
              </div>
              : null
            }
            Kuorman koko: {Math.round(mass.get('truck_mass') * 100) / 100}
            <br/>
            Kuorman kaista massa: {Math.round(mass.get('mass') * 100) / 100}
            <br/>
            Lämpötila: {mass.get('temperature') || '-'} °C
            { mass.get('milling_deepness_right') ?
              <div>
                Jysintäsyvyys oikea: {mass.get('milling_deepness_right')} mm
                <br/>
                Jysintäsyvyys vasen: {mass.get('milling_deepness_left')} mm
                <br/>
                Kuumennetun alustan lämpötila: {mass.get('REM_temperature')} °C
              </div>
              : null
            }
            { mass.get('load') != null ?
              <p className='center'>
                <button onClick={this.showLoadDataOfMass.bind(this, mass.get('load'))}>Kuorma tiedot</button>
              </p>
              :
              <p className='center'>
                Ei kuorma tietoja
              </p>
            }
          </span>
        );

        const mapPath = props.mapPaths[path.get('id')];

        if (mapPath.length === 1) {
          mapPath.push(mapPath[0]);
        }

        lines.push(
          <Polyline key={path.get('id')}
                    positions={mapPath}
                    weight={10}
                    opacity={0.6}
                    color={color}
                    onClick={this.selectLine.bind(null, path.get('id'))}
                  >
            <Popup autoPan={false}>
              {text}
            </Popup>
          </Polyline>
        );
      }
    }

    this.setState({ lines: lines })
  }

  updateDimensions() {
    const height = window.innerHeight * 0.6;
    this.setState({ height: height })
  }

  getTrucks() {
    let trucks = [];

    fetch('/vehicles/' + this.props.organizationId).then(data => {
      if (!data.vehicles) return;

      data.vehicles.forEach(truck => {
        if (truck['latitude'] != null) {
          const date = new Date(truck['time']);
          const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
          + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());
    
          let loaded = false;
    
          if (this.props.loads) {
            loaded = this.props.loads.includes(load => load.get('truck') === truck['vehicleId']);
          }
    
          trucks.push(
            <Marker key={truck['vehicleId']}
                    position={[truck['latitude'], truck['longitude']]}
                    icon={new L.Icon({
                      iconUrl: loaded ? 'truck_loaded.gif' : 'truck.gif',
                      iconSize: [45, 36],
                      iconAnchor: [23, 36],
                      popupAnchor: [null, -36]
                    })}>
              <Popup autoPan={false}>
                <span>
                  {truck['vehicleId']}
                </span>
                <br/>
                <span>
                  Viimeisin aika: {time}
                </span>
              </Popup>
            </Marker>
          );
        }
      });

      this.setState({ trucks: trucks });
    }).catch(error => {
      console.log(error);
    });
  }

  showLoadDataOfMass(id) {
    fetch('/coatingplantmasses?load=' + id).then(data => {
      this.setState({
        loadDataOfMass: data
      })
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimelta ei saatu kuorman tietoja', 'Error');
    });
  }

  clearLoadDataOfMass() {
    this.setState({ 
      loadDataOfMass: []
    })
  }

  changeDirection(value, event) {
    let directions = this.state.directions.slice();

    if (event.target.checked) {
      directions.push(value);
    }
    else {
      const index = directions.findIndex(direction => direction === value);
      directions.splice(index, 1);
    }

    this.setState({ directions: directions });
  }

  selectLine(index) {
    this.setState({
      selectedLine: index
    }, () => {
        this.update(this.props, this.state);
    });
  }

  render() {
    return (
      <div>
        <div className='center'>
          {'Suunnat: '}
          <label>
            1
            <input id='direction1' type='checkbox' name='direction' value='1'
              onChange={this.changeDirection.bind(this, 1)}
              defaultChecked={this.state.directions.includes(1)}/>
          </label>
          <label>
            2
            <input id='direction2' type='checkbox' name='direction' value='2'
              onChange={this.changeDirection.bind(this, 2)}
              defaultChecked={this.state.directions.includes(2)}/>
          </label>
        </div>
        <div style={{ height: this.state.height }}>
          <link rel='stylesheet' href='https://unpkg.com/leaflet@1.4.0/dist/leaflet.css'
                integrity='sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=='
                crossOrigin='' />
          <Map ref={element => this.map = element}
               id="map-area" center={this.position} zoom={this.zoom} maxZoom={this.state.maxZoom}
               onClick={this.selectLine.bind(null, null)}>
          <TileLayer url={this.state.mapTilesUrl}
                     attribution={this.state.mapTilesAttribution}
                     maxZoom={this.state.maxZoom} />
            { this.props.site != null && this.props.site.get('coating_plant_laditude') != null ? (
                <Marker position={[this.props.site.get('coating_plant_laditude'), this.props.site.get('coating_plant_longitude')]}
                        icon={new L.Icon({iconUrl: 'coating_plant.gif',
                              iconSize: [33, 50],
                              iconAnchor: [17, 50],
                              popupAnchor: [null, -50]
                              })}>
                  <Popup autoPan={false}>
                    <span>{this.props.site.get('coating_plant_laditude')}, {this.props.site.get('coating_plant_longitude')}</span>
                  </Popup>
                </Marker>
              ) : null }
            { this.props.yourLaditude != null ? (
                <Marker position={[this.props.yourLaditude, this.props.yourLongitude]}
                        icon={new L.Icon({iconUrl: 'your_location.gif',
                              iconSize: [18, 43],
                              iconAnchor: [9, 43],
                              popupAnchor: [null, -43]
                              })}>
                  <Popup autoPan={false}>
                    <span>{this.props.yourLaditude}, {this.props.yourLongitude}</span>
                  </Popup>
                </Marker>
              ) : null }
            {this.state.lines}
            {this.state.trucks}
          </Map>
        </div>
        {this.state.showedWarning ? 'Yhtä tai useampaa kuormaa ei voida näyttää sijainti tiedon virheellisyyden vuoksi.' : ''}
        <LoadDataOfMass data={this.state.loadDataOfMass} clear={this.clearLoadDataOfMass}/>
        { this.props.masses.size !== 0 && this.props.mapPaths.length === 0 ?
          <div className='main loader'/> : null
        }
      </div>
    );
  }
};
