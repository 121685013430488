import { fromJS } from 'immutable';

const initialState = fromJS({
  loads: [],
  selectedLoad: null
});

export default (state = initialState,
    action) => {
  switch (action.type) {

    case 'ADD_LOADS': {
      return state.set('loads', fromJS(action.loads));
    }

    case 'SELECT_LOAD': {
      return state.set('selectedLoad', action.load);
    }

    default: {
      return state;
    }
  }
};